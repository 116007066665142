import { Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { useGetReportdetails } from 'features/community/use-cases/get-reportdetail';
import { useUpdateTerm } from 'features/community/use-cases/update.term';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';

import { ReportInfoFormItems } from './report-info-form-items';
export const ReportViewModal = ({
  onClose,
  id,
}: {
  onClose: () => void;
  id: string;
}) => {
  //   const { id } = useParams();
  const fieldQueryResult = useGetReportdetails(`post_id=${id}`, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });
  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data: reportdata,
    isError: isErrorQuestion,
  } = fieldQueryResult;
  const { isLoading: isLoadingUpdateQuestion } = useUpdateTerm();
  const isLoading = {
    loading: isLoadingGet || isFetchingGet || isLoadingUpdateQuestion,
    isFetchingGet,
    isLoadingUpdateQuestion,
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal cstmmdlright'
        title={'Reported User List'}
        onCancel={() => {
          document.body.style.overflow = 'auto';
          onClose(); // Call onClose function
        }}
        getContainer={false}
        footer={
          [
            // Only render the OK button
            // <Button key="submit" type="primary" onClick={form.submit}>
            //   Update Term & Conditions
            // </Button>,
          ]
        }
      >
        {isFetchingGet ? (
          <Loader />
        ) : isErrorQuestion ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <ReportInfoFormItems report={reportdata} />
            {/* <PasswordFormItems title={t('adminUsers.accountInfo')} /> */}
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
