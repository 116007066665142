import { App } from 'antd';
import { handleError } from 'api';
import { AxiosResponse } from 'axios';
import { GetReportDTO, ReportApiService } from 'features/reports';
import i18n from 'locales/i18n';
import { formatDate } from 'utils/date';

const { getCustomerReport } = ReportApiService();

export const useGetCustomerReport = () => {
  const { message } = App.useApp();

  const downloadCustomerWalletReport = async (payload: GetReportDTO) => {
    await getCustomerReport(payload)
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `wallet_report_${formatDate({
            date: payload.data.from_date,
            format: 'date',
          })}_${formatDate({
            date: payload.data.to_date,
            format: 'date',
          })}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        message.success(i18n.t('reports.successfulDownload'));
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        console.error(uiMessage);

        message.warning(uiMessage);
      });
  };

  return {
    downloadCustomerWalletReport,
  };
};
