import { Form } from 'antd';
import { ContentFilters } from 'components';
import dayjs from 'dayjs';
import { DiscountCodeTable } from 'features/discount-code/components/discount-code-table/discount-code-table-data';
import { DiscountCodeFilterForm } from 'features/discount-code/components/filter-form/discount-code-filter-form/discount-code-filter-form';
import {
  DiscountCodeFilterFormPaginated,
  DiscountCodeFilterFormValues,
} from 'features/discount-code/components/filter-form/discount-code-filter-form/type';
import { DiscountCodeTopBar } from 'features/discount-code/components/top-bar/discount-code-top';
import { useGetDiscountCouponList } from 'features/discount-code/use-cases/get-discount-code-list';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { formatDate } from 'utils/date';

const initialFilterValues: DiscountCodeFilterFormValues = {
  search: undefined,
  discounttypeid: undefined,
  date: [undefined, undefined],
  IsActive: undefined,
};

export const DiscountCodeView = () => {
  const { params, updateParams, resetPage } =
    usePaginationParams<DiscountCodeFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const preparePayload = (paramsw: DiscountCodeFilterFormPaginated) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];

    return {
      ...omit(params, ['date']),
      fromDate: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      toDate: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
    };
  };
  const { data, isFetching, refetch } = useGetDiscountCouponList(
    preparePayload(params),
  );
  const handleFiltersChange = (values: DiscountCodeFilterFormValues) => {
    updateParams(values);
    resetPage();
  };
  const formId = 'discount-filters-form';
  const [form] = Form.useForm<DiscountCodeFilterFormValues>();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/discount-code/list') {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [location]);

  return (
    <div>
      <DiscountCodeTopBar
        refetch={refetch}
        form={form}
        resetPage={resetPage}
        handleFilter={handleFiltersChange}
        initialValues={initialFilterValues}
      />
      <ContentFilters formId={formId}>
        <DiscountCodeFilterForm
          form={form}
          formId={formId}
          handleFilter={handleFiltersChange as any}
          initialValues={initialFilterValues as any}
        />
      </ContentFilters>
      <DiscountCodeTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
      />

      <Outlet />
    </div>
  );
};
