import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { OfferFilterFormField, OfferFilterFormValues } from './type';

const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: OfferFilterFormValues) => void;
  initialValues: OfferFilterFormValues;
  closeModal?: () => void;
  form: any;
};

export const OfferFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
  form,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  // const [form] = Form.useForm<OfferFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { RangePicker } = DatePicker;

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: OfferFilterFormValues) => {
    handleFilter(values);
    // setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <div className='notify_form'>
        <Form<OfferFilterFormValues>
          id={formId}
          name={formId}
          form={form}
          layout='vertical'
          onFinish={handleOnFinish}
          initialValues={initialValues}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
        >
          <ContentFilters.FormItemsWrapper>
            <StandardItemsWrapper>
              <Form.Item<OfferFilterFormValues>
                label={'Title'}
                name={OfferFilterFormField.title}
              >
                <Input placeholder='Search By the discount code and facility name' />
              </Form.Item>

              <Form.Item<OfferFilterFormValues>
                label={'Date'}
                name={OfferFilterFormField.Date}
              >
                <RangePicker
                  allowEmpty={[true, true]}
                  format={DATE_FORMATS.date}
                />
              </Form.Item>

              <Form.Item<OfferFilterFormValues>
                label={'Status'}
                name={OfferFilterFormField.status}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: true, label: 'Active' },
                    { value: false, label: 'InActive' },
                  ]}
                  placeholder='Select Status'
                />
              </Form.Item>
            </StandardItemsWrapper>
          </ContentFilters.FormItemsWrapper>

          {screens.lg && (
            <ContentFilters.ButtonsWrapper>
              <Button type='primary' htmlType='submit'>
                {t('wallet.buttons.filter', { count: 1 })}
              </Button>
              <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
            </ContentFilters.ButtonsWrapper>
          )}
        </Form>
      </div>
    </>
  );
};
