import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum DiscountCodeFilterFormField {
  title = 'search',
  DiscountCodetypeid = 'discounttypeid',
  Date = 'date',
  status = 'IsActive',
}
export type DiscountCodeFilterFormValues = {
  [DiscountCodeFilterFormField.title]: string | undefined;
  [DiscountCodeFilterFormField.DiscountCodetypeid]: string | undefined;
  [DiscountCodeFilterFormField.Date]:
    | [Dayjs | undefined, Dayjs | undefined]
    | null;
  [DiscountCodeFilterFormField.status]: string | undefined;
};

export type DiscountCodeFilterFormPaginated = DiscountCodeFilterFormValues &
  PaginatedRequestParams;
