import { PlusOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Grid,
  Image,
  Input,
  message,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/lib/upload';
import { Modal } from 'components';
import dayjs from 'dayjs';
import { NotificationApiService } from 'features/notification/api/notification-api.service';
import { useEditNotification } from 'features/notification/use-cases/edit-notification-data';
import { useEffect, useState } from 'react';
// import ReactQuill from 'react-quill';

const { useBreakpoint } = Grid;

export const EditNotificationModal = ({ onClose, id, onSuccess }: any) => {
  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };
  const formName = 'edit-notification-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [ScheduledDate, setScheduledDate] = useState<any>(null);
  const { isLoading, mutate } = useEditNotification();

  const [language, setLanguage] = useState('');

  const [redirectVal, setRedirectVal] = useState<any>(null);
  const [sportsData, setSportsData] = useState<any>(null); // Track 'Send & Later' action
  const [facilityData, setFacilityData] = useState<any>(null); // Track 'Send & Later' action
  const [packagesData, setPackagesData] = useState<any>(null);

  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  // Fetch notification details to populate the form
  const intialsDeatils = async () => {
    try {
      const apiRes = await NotificationApiService().getNotificationdetails({
        id: id,
      } as any);
      setScheduledDate(apiRes?.items[0]?.ScheduleTime);
      // Set form values based on fetched details
      form.setFieldsValue({
        Title: apiRes?.items[0].Title,
        DeviceTypeId: apiRes?.items[0]?.DeviceTypeId,
        RedirectionType: apiRes?.items[0]?.RedirectionType,
        Body: apiRes?.items[0]?.Body,
        ScheduledDate:
          apiRes?.items[0]?.ScheduleTime &&
          dayjs(apiRes?.items[0]?.ScheduleTime), // Convert ScheduleTime to a dayjs object
        SportId: apiRes?.items[0]?.SportId,
        FacilityId: apiRes?.items[0]?.FacilityId,
        ItemId: apiRes?.items[0]?.ItemId,
        IsSlot: apiRes?.items[0]?.IsSlot ? '1' : '0',
      });
      setRedirectVal(apiRes?.items[0]?.RedirectionType);
      getFacilityData(
        apiRes?.items[0]?.RedirectionType,
        apiRes?.items[0]?.SportId,
      );
      getSportsData(apiRes?.items[0]?.RedirectionType);
      getPackagesData(apiRes?.items[0]?.FacilityId);

      // Set file list if there is an image
      if (apiRes?.items[0]?.Image) {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: apiRes?.items[0].Image,
          },
        ]);
      }
    } catch (error: any) {
      message.error(error);
    }
  };

  const handleUpdate = async (formData: any) => {
    const formDataToSend = new FormData();
    formDataToSend.append('id', id);
    formDataToSend.append('Title', formData?.Title);
    formDataToSend.append('DeviceTypeId', formData?.DeviceTypeId);
    if (formData?.RedirectionType)
      formDataToSend.append('RedirectionType', formData?.RedirectionType);
    if (formData?.SportId) formDataToSend.append('SportId', formData?.SportId);
    if (formData?.FacilityId)
      formDataToSend.append('FacilityId', formData?.FacilityId);
    if (formData?.ItemId) formDataToSend.append('ItemId', formData?.ItemId);
    formDataToSend.append('Body', formData?.Body);

    formDataToSend.append(
      'IsSlot',
      formData?.IsSlot === '1' ? 'true' : 'false',
    );
    if (fileList && fileList.length > 0) {
      const file: any = fileList[0].originFileObj; // Access the file from the fileList state
      formDataToSend.append('file', file); // Append actual file (not metadata)
    }
    const actionId: any = 1; // Default action ID for "save"
    formDataToSend.append('ActionId', actionId);
    if (formData?.ScheduledDate) {
      const formattedDate = dayjs(formData?.ScheduledDate).format(
        'YYYY-MM-DD HH:mm',
      ); // Format the date using dayjs
      formDataToSend.append('ScheduleTime', formattedDate); // Send the formatted date in the payload
    }
    mutate(formDataToSend, { onSuccess });
  };

  //   const handlePreview = async (file: UploadFile) => {
  //     setPreviewImage(file.url || (file.preview as string));
  //     setPreviewOpen(true);
  //   };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    intialsDeatils(); // Fetch details when the component mounts
  }, []);

  const getSportsData = async (redValue: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: redValue === 'Booking' ? 'BookingSports' : 'PackageSports',
      } as any);
      setSportsData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getFacilityData = async (redirectLabel: string, sportsId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag:
          redirectLabel === 'Booking' ? 'BookingFacility' : 'PackageFacility',
        sport_id: sportsId,
      } as any);
      setFacilityData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getPackagesData = async (facilityId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: 'FacilityWisePackage',
        facility_id: facilityId,
      } as any);
      setPackagesData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const handleRedirectionChange = (value: string) => {
    form.setFieldValue('RedirectionType', value);
    setRedirectVal(value);
    getSportsData(value);
    form.resetFields(['SportId', 'FacilityId', 'ItemId']);
  };

  const handleSportsChange = (value: string) => {
    form.setFieldValue('SportId', value);
    form.resetFields(['FacilityId']);
    // const reLabel = redirectVal === "Booking" ? 'BookingFacility' : 'PackageFacility'
    getFacilityData(redirectVal, value);
  };

  const handleFacilityChange = (value: string) => {
    form.setFieldValue('FacilityId', value);
    form.resetFields(['ItemId']);
    getPackagesData(value);
  };

  const getBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob); // Generate preview for local files
    }
    setPreviewImage(file.url || (file.preview as any)); // Use URL if available; otherwise, use preview
    setPreviewOpen(true);
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Edit Notification'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText={'Update'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
    >
      <Form
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate as any}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Form.Item
            label='Language'
            // name='LanguageId'
            // rules={[{ required: true, message: 'Please select Language' }]}
          >
            <Select
              value={language}
              placeholder='Please select Language'
              options={[
                { value: '', label: 'English' },
                { value: 'rtl', label: 'Arabic' },
              ]}
              onChange={(value) => setLanguage(value)}
            />
          </Form.Item>

          {/* DeviceType Field */}
          <Form.Item
            label='DeviceType'
            name='DeviceTypeId'
            rules={[{ required: true, message: 'Please select DeviceType ' }]}
          >
            <Select
              placeholder='Please select DeviceType'
              options={[
                { value: 1, label: 'All' },
                { value: 2, label: 'Android' },
                { value: 3, label: 'iOS' },
              ]}
            />
          </Form.Item>

          {/* Title Field */}
          <Form.Item
            label='Title'
            name='Title'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter title',
              },
            ]}
          >
            <Input placeholder='Enter your Title' maxLength={54} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const calculateUTF16Length = (str: string) => {
                let length = 0;
                for (let i = 0; i < str.length; i++) {
                  const code = str.charCodeAt(i);
                  if (code >= 0xd1000 && code <= 0xdbff) {
                    // Surrogate pair: increment index and count as one character
                    i++;
                  }
                  length++;
                }

                return length;
              };

              const input = getFieldValue('Title') || '';
              const remaining = 54 - calculateUTF16Length(input);

              return (
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '12px',
                    color: '#888',
                    marginTop: '-20px',
                  }}
                >
                  {remaining}
                </div>
              );
            }}
          </Form.Item>

          {/* Body Field */}
          <Form.Item
            label='Body'
            name='Body'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter body text',
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder='Please Type message...'
              dir={language}
              maxLength={160}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const calculateUTF16Length = (str: string) => {
                let length = 0;
                for (let i = 0; i < str.length; i++) {
                  const code = str.charCodeAt(i);
                  if (code >= 0xd800 && code <= 0xdbff) {
                    // Surrogate pair: increment index and count as one character
                    i++;
                  }
                  length++;
                }

                return length;
              };

              const input = getFieldValue('Body') || '';
              const remaining = 160 - calculateUTF16Length(input);

              return (
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '12px',
                    color: '#888',
                    marginTop: '-20px',
                  }}
                >
                  {remaining}
                </div>
              );
            }}
          </Form.Item>

          {/* Redirection Field */}
          <Form.Item
            label='Redirection'
            name='RedirectionType'
            // rules={[{ required: true, message: 'Please select Redirection' }]}
          >
            <Select
              allowClear
              placeholder='Please select Redirection'
              options={[
                { value: 'Booking', label: 'Booking' },
                { value: 'Match', label: 'Match' },
                { value: 'Package', label: 'Package' },
              ]}
              onChange={handleRedirectionChange}
            />
          </Form.Item>

          {/* sports id */}

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const redirectionValue = getFieldValue('RedirectionType');
              if (
                redirectionValue === 'Package' ||
                redirectionValue === 'Booking'
              ) {
                return (
                  <Form.Item
                    label='Sports'
                    name='SportId'
                    rules={[
                      { required: true, message: 'Please select Sports' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Sports'
                      options={sportsData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleSportsChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const sportsValue = getFieldValue('SportId');
              if (sportsValue) {
                return (
                  <Form.Item
                    label='Facility'
                    name='FacilityId'
                    rules={[
                      { required: true, message: 'Please select Facility' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Facility'
                      options={facilityData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleFacilityChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Package' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Package'
                    name='ItemId'
                    rules={[
                      { required: true, message: 'Please select Package' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Package'
                      options={packagesData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* is slot - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Booking' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Page'
                    name='IsSlot'
                    // rules={[{ required: true, message: 'Please select Package' }]}
                  >
                    <Select
                      defaultValue={'0'}
                      placeholder='Please select Page'
                      options={[
                        { value: '0', label: 'Detail' },
                        { value: '1', label: 'Slot' },
                      ]}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Scheduled Date Field */}
          {ScheduledDate && (
            <Form.Item
              label='Schedule Time'
              name='ScheduledDate'
              rules={[
                { required: true, message: 'Please select scheduled date' }, // Required rule
                {
                  validator: (_, value) => {
                    // Custom validation logic
                    if (value && value.isBefore(dayjs())) {
                      return Promise.reject(
                        'Selected date and time cannot be in the past',
                      );
                    }

                    return Promise.resolve(); // Validation passed
                  },
                },
              ]}
            >
              <DatePicker
                defaultPickerValue={dayjs().add(1, 'day')}
                showTime={{ format: 'HH:mm' }} // Only show hours and minutes
                format='YYYY-MM-DD HH:mm' // Format the date and time together
                disabledDate={(current) => {
                  return current && current < dayjs().startOf('day');
                }}
                disabledTime={(current) => {
                  // Disable past times for today
                  if (current && current.isSame(dayjs(), 'day')) {
                    const now = dayjs();

                    return {
                      disabledHours: () =>
                        Array.from({ length: 24 }, (_, i) => i).filter(
                          (hour) => hour < now.hour(),
                        ),
                      disabledMinutes: (selectedHour) =>
                        selectedHour === now.hour()
                          ? Array.from({ length: 60 }, (_, i) => i).filter(
                              (minute) => minute < now.minute(),
                            )
                          : [],
                    };
                  }

                  return {};
                }}
              />
            </Form.Item>
          )}

          {/* File Upload */}
          <Form.Item label='Upload Image'>
            <Upload
              action={undefined}
              beforeUpload={(file) => {
                const isImage = [
                  'image/jpeg',
                  'image/png',
                  'image/jpg',
                ].includes(file.type);
                const isSize = file.size < 2097153;

                if (!isImage) {
                  message.error('You can only upload JPG, JPEG, or PNG files!');
                }

                if (!isSize) {
                  message.error(
                    'You can only upload maximum 2048 kb image size',
                  );
                }

                return isImage && isSize ? false : Upload.LIST_IGNORE; // Ignore non-image files
              }}
              listType='picture-card'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: setPreviewOpen,
                }}
                src={previewImage}
                alt='Preview Image'
              />
            )}
          </Form.Item>
        </figure>
      </Form>
    </Modal>
  );
};
