import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateApprovedDTO,
  WithdrawApiService,
} from 'features/withdraw/api/withdraw-api.service';

import { withdrawQueryKeys } from './query-keys';
const { createSuperAdminReject } = WithdrawApiService();
// ../api/withdraw-api.service
export const useCreateSuperAdminReject = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateApprovedDTO) => {
      return createSuperAdminReject(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: withdrawQueryKeys.all,
        });
        if (data?.response_status == 0) {
          message.error(data?.response_message);
        } else {
          message.success(data?.response_message);
        }
      },
    },
  );
};
