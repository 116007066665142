import { ContentFilters, ContentTopBar } from 'components';
import dayjs from 'dayjs';
import { useUserContext } from 'features/users';
import { SuperFilterForm } from 'features/withdraw/components/super-admin-filter/super-with-admin-filter-form';
import { SuperAdminTable } from 'features/withdraw/components/super-admin-table/super-with-admin-table';
import {
  UsersWithWithdrawFilterFormPaginated,
  UsersWithWithdrawFilterFormValues,
} from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { useGetUsersWithWithdraw } from 'features/withdraw/use-cases/get-withdraw';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { formatDate } from 'utils/date';
// ../components/super-admin-filter/super-with-admin-filter-form
// ../components/super-admin-table.tsx/super-with-admin-table
const initialFilterValues: UsersWithWithdrawFilterFormValues = {
  user_name: undefined,
  phone_number: undefined,
  refrence_no: undefined,
  status_id: undefined,
  remark: undefined,
  date: null,
};

export const SuperAdminView = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [queryFilters, setQueryFilters] = useState<any>(initialFilterValues);
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];
  const { params, updateParams, resetPage } =
    usePaginationParams<UsersWithWithdrawFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by_transaction: '-last_transaction_date',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetUsersWithWithdraw(
    {
      page: params.page,
      size: params.size,
      adminuser: 'superadmin',
      from_date: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      to_date: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      phone_number: queryFilters.phone_number,
      user_name: queryFilters.user_name,
      refrence_no: queryFilters.refrence_no,
      status_id: queryFilters.status_id,
    } as any,
    {
      retry: false,
    },
  );

  const handleFiltersChange = (values: UsersWithWithdrawFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  useEffect(() => {
    if (user?.role && user.role == 'Li3ibAccountant') {
      navigate(ROUTES.WITHDRAW);
    }
  }, [user, data]);

  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar title={'Super Admin'} />

      <ContentFilters formId={formId}>
        <SuperFilterForm
          formId={formId}
          setQueryFilters={setQueryFilters}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <SuperAdminTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
