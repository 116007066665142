import { UploadFile } from 'antd';
import { useState } from 'react';

export const useUploadProps = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploadProps = {
    onRemove: (file: UploadFile) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return {
    uploadProps,
    fileList,
    setFileList,
  };
};
