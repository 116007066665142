import {
  CalendarOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { Booking, PaymentMethod } from 'types';
import { formatPrice } from 'utils/currency';
import {
  DEFAULT_TIMEZONE,
  formatDate,
  formatDateRange,
  getDuration,
} from 'utils/date';
import { paymentMethodTranslationsMap } from 'utils/transaction';

import {
  StyledAdditionalItemText,
  StyledDetailsItem,
  StyledItemText,
  StyledTruncationWrapper,
  StyledWrapper,
} from './booking-details-info.styles';

type BookingDetailsInfoProps = {
  startDate: string;
  endDate: string;
  fieldName: string;
  companyName: string;
  facilityName: string;
  initialPrice: number;
  finalPrice: number;
  discount: number;
  paymentMethod: PaymentMethod;
  split_transactionList: any;
  paid_amount: any;
  transaction: any;
};

export const getBookingDetailsInfoProps = (booking: Booking) => {
  return {
    startDate: booking.start_date,
    endDate: booking.end_date,
    fieldName: booking.field.name,
    facilityName: booking.field.facility.name,
    companyName: booking.field.facility.company.name,
    initialPrice: booking?.transaction?.total_cost || 0,
    finalPrice: booking?.transaction?.total_cost_with_discount || 0,
    discount: booking?.transaction?.discount || 0,
    paymentMethod: booking?.transaction?.payment_method || '',
    split_transactionList: booking?.transaction?.split_transactionList,
    paid_amount: booking?.transaction?.paid_amount,
    transaction: booking?.transaction,
  };
};

export const BookingDetailsInfo = ({
  startDate,
  endDate,
  fieldName,
  companyName,
  facilityName,
  initialPrice,
  finalPrice,
  discount,
  paymentMethod,
  split_transactionList,
  // paid_amount,
  transaction,
}: // split_transactionList,
BookingDetailsInfoProps) => {
  const { t } = useTranslation();
  const duration = getDuration(startDate, endDate);
  const amount = transaction?.paid_amount ?? 0;
  const adjustedAmount = amount < 0 ? 0 : amount;

  const formattedPrice = formatPrice({
    value: adjustedAmount,
    currency: 'KWD',
  });

  return (
    <StyledWrapper>
      <StyledDetailsItem>
        <CalendarOutlined className='booking-details-icon' />
        <div>
          <StyledItemText>
            {formatDate({
              date: startDate,
              format: 'dateWithDayName',
              timeZone: DEFAULT_TIMEZONE,
            })}
          </StyledItemText>
        </div>
      </StyledDetailsItem>

      <StyledDetailsItem>
        <ClockCircleOutlined />
        <div>
          <StyledItemText>
            {formatDateRange({
              startDate: startDate,
              endDate: endDate,
            })}
          </StyledItemText>
          <StyledAdditionalItemText type='secondary'>
            {t('common.minutes.short', { value: duration })}
          </StyledAdditionalItemText>
        </div>
      </StyledDetailsItem>

      <StyledDetailsItem>
        <EnvironmentOutlined className='booking-details-icon' />
        <StyledTruncationWrapper>
          <StyledItemText ellipsis={{ tooltip: true }}>
            {fieldName}
          </StyledItemText>
          <StyledAdditionalItemText
            type='secondary'
            ellipsis={{ tooltip: true }}
          >
            {companyName} - {facilityName}
          </StyledAdditionalItemText>
        </StyledTruncationWrapper>
      </StyledDetailsItem>
      {/* {transaction?.is_split_payment==false } */}
      {transaction?.is_split_payment == false || transaction == null ? (
        <div>
          <StyledDetailsItem>
            <DollarCircleOutlined className='booking-details-icon' />
            <StyledTruncationWrapper>
              <Space size='small'>
                <Badge
                  count={formatPrice({
                    value: finalPrice,
                    currency: 'KWD',
                  })}
                  showZero
                  color={colors.gray3}
                  className='booking-details-price-badge'
                />
                <StyledItemText>
                  {paymentMethodTranslationsMap[paymentMethod]}
                </StyledItemText>
              </Space>

              {discount > 0 ? (
                <StyledAdditionalItemText
                  type='secondary'
                  ellipsis={{ tooltip: true }}
                >
                  {t('bookings.details.discountedFromTo', {
                    from: formatPrice({
                      value: initialPrice,
                      currency: 'KWD',
                    }),
                    to: formatPrice({
                      value: finalPrice,
                      currency: 'KWD',
                    }),
                  })}
                </StyledAdditionalItemText>
              ) : null}
            </StyledTruncationWrapper>
          </StyledDetailsItem>
        </div>
      ) : (
        <div>
          <div className='bk_info_wp'>
            <div className='bk_title'>
              <DollarCircleOutlined className='booking-details-icon' />
              <div className='bk_info_inner'>
                <h3>
                  <span> Total Amount</span>{' '}
                  <span>
                    {formatPrice({
                      value: finalPrice,
                      currency: 'KWD',
                    })}
                  </span>
                </h3>
                <h3>
                  <span>Paid Amount</span>
                  {/* {transaction?.paid_amount>0 ? (
                    <span style={{ color: 'red' }}>
                      {formatPrice({
                        value: paid_amount,
                        currency: 'KWD',
                      })}
                    </span>
                  ) : (
                    <span style={{ color: 'green' }}>
                      {formatPrice({
                        value: paid_amount,
                        currency: 'KWD',
                      })}
                    </span>
                  )} */}
                  <span
                    style={{ color: adjustedAmount === 0 ? 'green' : 'green' }}
                  >
                    {formattedPrice}
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <div className='bk_info_dta'>
            <ul>
              {split_transactionList?.map((res: any, index: number) => {
                return (
                  <>
                    <li key={index} style={{ flexWrap: 'wrap' }}>
                      <span>{res?.amount} KD </span>
                      <span>{res?.payment_method}</span>
                      <span>By {res?.paid_by}</span>
                      {(res?.payment_method === 'KNET' ||
                        res?.payment_method === 'CREDITCARD' ||
                        res?.payment_method === 'APPLEPAY') && (
                        <span
                          style={{
                            display: 'block',
                            width: '100%',
                            textAlign: 'left',
                            padding: '2px 0px',
                          }}
                        >
                          Order No {res?.order_no}
                        </span>
                      )}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};
