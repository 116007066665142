import { EditOfferBannerModal } from 'features/discount-code/components/modal/edit-offer-banner-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const EditOfferBannerEditView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.DISCOUNT_CODE_OFFER_BANNER);
    document.body.style.overflow = 'visible';
  };

  return (
    <EditOfferBannerModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
