import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { NotificationApiService } from 'features/notification/api/notification-api.service';

import { NotificationQueryKeys } from './query.keys';

const { editNotification } = NotificationApiService();

export const useEditNotification = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FormData) => {
      return editNotification(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: NotificationQueryKeys.all,
        });
        if (data?.ResponseStatus == 1) {
          message.success('Notification Edit successfully');
        }
      },
    },
  );
};
