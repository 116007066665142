import { EditDiscountCodeModal } from 'features/discount-code/components/modal/edit-discount-code-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const DiscountCodeEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.DISCOUNT_CODE);
    document.body.style.overflow = 'visible';
  };

  return (
    <EditDiscountCodeModal
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
