import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
import { axiosInstance } from 'api/axios-instance';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export const DiscountApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;

  const getDiscountDropDown = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/DiscountCoupon/dropdown`,
      params,
    });

    return response.data;
  };

  const getDiscountCouponList = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/DiscountCoupon`,
      params,
    });

    return response.data;
  };

  const createDiscount = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/DiscountCoupon/Generate`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getDiscountDetails = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/DiscountCoupon`,
      params,
    });

    return response.data;
  };

  const updateDiscountCodeStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/DiscountCoupon/UpdateStatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getOfferBannerList = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/OfferBanner`,
      params,
    });

    return response.data;
  };

  const getOfferBannerById = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/OfferBanner`,
      params,
    });

    return response.data;
  };

  const createOfferBanner = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${baseURL}/admin/OfferBanner`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getOfferList = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Offer`,
      params,
    });

    return response.data;
  };

  const createOffer = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${baseURL}/admin/Offer`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  return {
    getDiscountDropDown,
    getDiscountCouponList,
    createDiscount,

    getDiscountDetails,
    updateDiscountCodeStatus,

    getOfferBannerList,
    createOfferBanner,
    getOfferBannerById,

    getOfferList,
    createOffer,
  };
};
