import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
import { axiosInstance } from 'api/axios-instance';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export const NotificationApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;
  const getNotification = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CustomNotification`,
      params,
    });

    return response.data;
  };
  const getNotificationdropDown = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CustomNotification/dropdown`,
      params,
    });

    return response.data;
  };

  const createNotification = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${baseURL}/admin/CustomNotification/Create`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const editNotification = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${baseURL}/admin/CustomNotification/Create`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const createNotificationSend = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/CustomNotification/send`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getNotificationdetails = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CustomNotification`,
      params,
    });

    return response.data;
  };

  const deleteNotification = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/CustomNotification/delete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getNotification,
    getNotificationdropDown,
    createNotification,
    createNotificationSend,
    getNotificationdetails,
    editNotification,
    deleteNotification,
  };
};
