import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import {
  NotificationFilterFormField,
  NotificationFilterFormValues,
} from './type';

const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: NotificationFilterFormValues) => void;
  initialValues: NotificationFilterFormValues;
  closeModal?: () => void;
  form: any;
};

export const NotificationFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
  form,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  // const [form] = Form.useForm<NotificationFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { RangePicker } = DatePicker;

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: NotificationFilterFormValues) => {
    handleFilter(values);
    // setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <div className='notify_form'>
        <Form<NotificationFilterFormValues>
          id={formId}
          name={formId}
          form={form}
          layout='vertical'
          onFinish={handleOnFinish}
          initialValues={initialValues}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
        >
          <ContentFilters.FormItemsWrapper>
            <StandardItemsWrapper>
              <Form.Item<NotificationFilterFormValues>
                label={'Notification Type'}
                name={NotificationFilterFormField.notificationtypeid}
              >
                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: '1', label: 'Create' },
                    { value: '2', label: 'SendNow' },
                    { value: '3', label: 'Scheduled' },
                  ]}
                  placeholder='Notification Type'
                />
              </Form.Item>
              <Form.Item<NotificationFilterFormValues>
                label={'Device Type'}
                name={NotificationFilterFormField.devicetypeid}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: '1', label: 'All' },
                    { value: '2', label: 'Android' },
                    { value: '3', label: 'iOS' },
                  ]}
                  placeholder='Device Type'
                />
              </Form.Item>
              <Form.Item<NotificationFilterFormValues>
                label={'Title'}
                name={NotificationFilterFormField.title}
              >
                <Input placeholder='Enter title' />
              </Form.Item>
              <Form.Item<NotificationFilterFormValues>
                label={'Date'}
                name={NotificationFilterFormField.Date}
              >
                <RangePicker
                  allowEmpty={[true, true]}
                  format={DATE_FORMATS.date}
                />
              </Form.Item>
            </StandardItemsWrapper>
          </ContentFilters.FormItemsWrapper>

          {screens.lg && (
            <ContentFilters.ButtonsWrapper>
              <Button type='primary' htmlType='submit'>
                {t('wallet.buttons.filter', { count: 1 })}
              </Button>
              <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
            </ContentFilters.ButtonsWrapper>
          )}
        </Form>
      </div>
    </>
  );
};
