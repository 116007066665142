import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Grid,
  Image,
  Input,
  message,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/lib/upload';
import { Modal } from 'components';
import dayjs from 'dayjs';
import { NotificationApiService } from 'features/notification/api/notification-api.service';
import { NotificationFilterFormValues } from 'features/notification/components/notification-filter-form/type';
import { useCreateNotification } from 'features/notification/use-cases/create-notification-items';
// import { useGetNotificationDropDownList } from 'features/notification/use-cases/get-notifications-drop-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ReactQuill from 'react-quill';

const { useBreakpoint } = Grid;

export const AddNotificationModal = ({
  onClose,
  initialValues,
  handleFilter,
  filterForm,
}: {
  onClose: () => void;
  handleFilter: (values: NotificationFilterFormValues) => void;
  initialValues: NotificationFilterFormValues;
  filterForm: any;
}) => {
  const formName = 'add-notification-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  // const { data: dropdownList } = useGetNotificationDropDownList({
  //   Flag: 'Facility',
  // } as any);
  // const [facilityValue, setFacilityValue] = useState<any>(null);
  const [sendLater, setSendLater] = useState<boolean>(false); // Track 'Send & Later' action

  const [redirectVal, setRedirectVal] = useState<any>(null);
  const [sportsData, setSportsData] = useState<any>(null); // Track 'Send & Later' action
  const [facilityData, setFacilityData] = useState<any>(null); // Track 'Send & Later' action
  const [packagesData, setPackagesData] = useState<any>(null);

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };

  const getSportsData = async (redValue: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: redValue === 'Booking' ? 'BookingSports' : 'PackageSports',
      } as any);
      setSportsData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getFacilityData = async (redirectLabel: string, sportsId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: redirectLabel,
        sport_id: sportsId,
      } as any);
      setFacilityData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getPackagesData = async (facilityId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: 'FacilityWisePackage',
        facility_id: facilityId,
      } as any);
      setPackagesData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const handleSendLaterClick = () => {
    setSendLater(true); // When "Send & Later" is clicked, show date and time picker
  };
  const { isLoading, mutate } = useCreateNotification();
  const handleUpdate = async (formData: any, actionType: string) => {
    const validate = await form.validateFields();

    if (!validate) return;

    const formDataToSend = new FormData();
    formDataToSend.append('Title', formData?.Title);
    formDataToSend.append('DeviceTypeId', formData?.DeviceTypeId);
    if (formData?.RedirectionType)
      formDataToSend.append('RedirectionType', formData?.RedirectionType);
    if (formData?.SportId) formDataToSend.append('SportId', formData?.SportId);
    if (formData?.FacilityId)
      formDataToSend.append('FacilityId', formData?.FacilityId);
    if (formData?.ItemId) formDataToSend.append('ItemId', formData?.ItemId);
    formDataToSend.append('Body', formData?.Body);

    formDataToSend.append(
      'IsSlot',
      formData?.IsSlot === '1' ? 'true' : 'false',
    );

    if (fileList && fileList.length > 0) {
      const file: any = fileList[0].originFileObj; // Access the file from the fileList state
      formDataToSend.append('file', file); // Append actual file (not metadata)
    }
    let actionId: any = 1; // Default action ID for "save"
    if (actionType === 'saveNow') {
      actionId = 2; // Action for saving now
    } else if (actionType === 'sendLater') {
      actionId = 3; // Action for scheduling later
    }
    formDataToSend.append('ActionId', actionId);
    // If you have a scheduled date, append it as well
    if (formData?.ScheduledDate) {
      const formattedDate = dayjs(formData?.ScheduledDate).format(
        'YYYY-MM-DD HH:mm',
      ); // Format the date using dayjs
      formDataToSend.append('ScheduleTime', formattedDate); // Send the formatted date in the payload
    }
    try {
      // Trigger your mutation with FormData
      mutate(formDataToSend, {
        onSuccess: () => {
          filterForm.resetFields();
          handleFilter({ ...initialValues });
          handleClose(); // Close modal or handle success state
        },
        onError: (error: any) => {
          console.error('Error creating notification:', error); // Handle error
        },
      });
    } catch (error) {
      console.error('Error during mutate:', error);
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // const handlePreview = async (file: UploadFile) => {
  //   setPreviewImage(file.url || (file.preview as string));
  //   setPreviewOpen(true);
  // };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob); // Generate preview for local files
    }
    setPreviewImage(file.url || (file.preview as any)); // Use URL if available; otherwise, use preview
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const [language, setLanguage] = useState('');
  // const [title, setTitle] = useState<string>('');
  // const maxLength = 54;

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTitle(e.target.value.slice(0, maxLength)); // Limit the input to maxLength
  // };

  // const [message, setMessage] = useState<string>('');
  // const messageLength = 160;

  // const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setMessage(e.target.value.slice(0, messageLength)); // Limit the input to maxLength
  //   form.setFieldValue("Message", message)
  // };

  const handleRedirectionChange = (value: string) => {
    form.setFieldValue('RedirectionType', value);
    setRedirectVal(value);
    getSportsData(value);
    form.resetFields(['SportId', 'FacilityId', 'ItemId']);
  };

  const handleSportsChange = (value: string) => {
    form.setFieldValue('SportId', value);
    form.resetFields(['FacilityId']);
    const reLabel =
      redirectVal === 'Booking' ? 'BookingFacility' : 'PackageFacility';
    getFacilityData(reLabel, value);
  };

  const handleFacilityChange = (value: string) => {
    form.setFieldValue('FacilityId', value);
    form.resetFields(['ItemId']);
    getPackagesData(value);
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Add Notification'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okText={t('social.formfield.addnewlabel')}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
      footer={
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          <Button
            type='primary'
            htmlType={'submit'}
            onClick={() => handleUpdate(form.getFieldsValue(), 'save')}
            disabled={sendLater}
            loading={isLoading}
          >
            Save
          </Button>
          <Button
            type='primary'
            onClick={() => handleUpdate(form.getFieldsValue(), 'saveNow')}
            disabled={sendLater}
            loading={isLoading}
          >
            Send Now
          </Button>
          {!sendLater && (
            <Button type='primary' onClick={handleSendLaterClick}>
              Send Later
            </Button>
          )}
          {sendLater && (
            <Button
              loading={isLoading}
              type='primary'
              onClick={() => handleUpdate(form.getFieldsValue(), 'sendLater')}
            >
              Send Later
            </Button>
          )}
        </div>
      }
    >
      <Form
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate as any}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Form.Item
            label='Language'
            // name='LanguageId'
            // rules={[{ required: true, message: 'Please select Language' }]}
          >
            <Select
              value={language}
              placeholder='Please select Language'
              options={[
                { value: '', label: 'English' },
                { value: 'rtl', label: 'Arabic' },
              ]}
              onChange={(value) => setLanguage(value)}
            />
          </Form.Item>

          <Form.Item
            label='DeviceType'
            name='DeviceTypeId'
            rules={[{ required: true, message: 'Please select DeviceType ' }]}
          >
            <Select
              placeholder='Please select DeviceType'
              options={[
                { value: 1, label: 'All' },
                { value: 2, label: 'Android' },
                { value: 3, label: 'iOS' },
              ]}
            />
          </Form.Item>

          {/* Title Field */}
          <Form.Item
            label='Title'
            name='Title'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter title',
              },
            ]}
          >
            <Input
              placeholder='Enter your Title'
              dir={language}
              maxLength={54}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const calculateUTF16Length = (str: string) => {
                let length = 0;
                for (let i = 0; i < str.length; i++) {
                  const code = str.charCodeAt(i);
                  if (code >= 0xd1000 && code <= 0xdbff) {
                    // Surrogate pair: increment index and count as one character
                    i++;
                  }
                  length++;
                }

                return length;
              };

              const input = getFieldValue('Title') || '';
              const remaining = 54 - calculateUTF16Length(input);

              return (
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '12px',
                    color: '#888',
                    marginTop: '-20px',
                  }}
                >
                  {remaining}
                </div>
              );
            }}
          </Form.Item>
          {/* <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <div
              style={{
                textAlign: 'right',
                fontSize: '12px',
                color: '#888',
                marginTop: '-20px',
              }}
            >
              {54 - Array.from(getFieldValue('Title') || '').length}
            </div>
          )}
        </Form.Item> */}

          {/* Message Field */}
          <Form.Item
            label='Message'
            name='Body'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter message',
              },
            ]}
          >
            {/* <ReactQuill className='editor-large' theme='snow' /> */}
            <TextArea
              rows={4}
              placeholder='Please Type message...'
              dir={language}
              maxLength={160}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const calculateUTF16Length = (str: string) => {
                let length = 0;
                for (let i = 0; i < str.length; i++) {
                  const code = str.charCodeAt(i);
                  if (code >= 0xd800 && code <= 0xdbff) {
                    // Surrogate pair: increment index and count as one character
                    i++;
                  }
                  length++;
                }

                return length;
              };

              const input = getFieldValue('Body') || '';
              const remaining = 160 - calculateUTF16Length(input);

              return (
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '12px',
                    color: '#888',
                    marginTop: '-20px',
                  }}
                >
                  {remaining}
                </div>
              );
            }}
          </Form.Item>
          {/* <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <div
              style={{
                textAlign: 'right',
                fontSize: '12px',
                color: '#888',
                marginTop: '-20px',
              }}
            >
              {160 - (getFieldValue('Body')?.length || 0)}
            </div>
          )}
        </Form.Item> */}

          {/* Redirection Field */}
          <Form.Item
            label='Redirection'
            name='RedirectionType'
            // rules={[{ required: true, message: 'Please select Redirection' }]}
          >
            <Select
              allowClear
              placeholder='Please select Redirection'
              options={[
                { value: 'Booking', label: 'Booking' },
                { value: 'Match', label: 'Match' },
                { value: 'Package', label: 'Package' },
              ]}
              onChange={handleRedirectionChange}
            />
          </Form.Item>

          {/* sports id */}

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const redirectionValue = getFieldValue('RedirectionType');
              if (
                redirectionValue === 'Package' ||
                redirectionValue === 'Booking'
              ) {
                return (
                  <Form.Item
                    label='Sports'
                    name='SportId'
                    rules={[
                      { required: true, message: 'Please select Sports' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Sports'
                      options={sportsData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleSportsChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const sportsValue = getFieldValue('SportId');
              if (sportsValue) {
                return (
                  <Form.Item
                    label='Facility'
                    name='FacilityId'
                    rules={[
                      { required: true, message: 'Please select Facility' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Facility'
                      options={facilityData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleFacilityChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Package' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Package'
                    name='ItemId'
                    rules={[
                      { required: true, message: 'Please select Package' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Package'
                      options={packagesData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* is slot - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Booking' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Page'
                    name='IsSlot'
                    // rules={[{ required: true, message: 'Please select Package' }]}
                  >
                    <Select
                      allowClear
                      defaultValue={'0'}
                      placeholder='Please select Page'
                      options={[
                        { value: '0', label: 'Detail' },
                        { value: '1', label: 'Slot' },
                      ]}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Upload Field */}
          <Form.Item label='File' name='file'>
            <Upload
              action={undefined}
              beforeUpload={(file) => {
                const isImage = [
                  'image/jpeg',
                  'image/png',
                  'image/jpg',
                ].includes(file.type);
                const isSize = file.size < 2097153;

                if (!isImage) {
                  message.error('You can only upload JPG, JPEG, or PNG files!');
                }

                if (!isSize) {
                  message.error(
                    'You can only upload maximum 2048 kb image size',
                  );
                }

                return isImage && isSize ? false : Upload.LIST_IGNORE; // Ignore non-image files
              }}
              listType='picture-card'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              multiple
              maxCount={1}
              accept='.jpg,.jpeg,.png'
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: setPreviewOpen,
                }}
                src={previewImage}
                alt='Preview Image'
              />
            )}
          </Form.Item>
          {/* Date and Time Picker - Conditionally Rendered for "Send & Later" */}
          {sendLater && (
            <>
              <Form.Item
                label='Schedule Time'
                name='ScheduledDate'
                rules={[
                  { required: true, message: 'Please select a date and time' }, // Required rule
                  {
                    validator: (_, value) => {
                      // Custom validation logic
                      if (value && value.isBefore(dayjs())) {
                        return Promise.reject(
                          'Selected date and time cannot be in the past',
                        );
                      }

                      return Promise.resolve(); // Validation passed
                    },
                  },
                ]}
              >
                <DatePicker
                  defaultPickerValue={dayjs().add(1, 'day')}
                  showTime={{ format: 'HH:mm' }} // Only show hours and minutes
                  format='YYYY-MM-DD HH:mm' // Format the date and time together
                  disabledDate={(current) => {
                    return current && current < dayjs().startOf('day');
                  }}
                  disabledTime={(current) => {
                    // Disable past times for today
                    if (current && current.isSame(dayjs(), 'day')) {
                      const now = dayjs();

                      return {
                        disabledHours: () =>
                          Array.from({ length: 24 }, (_, i) => i).filter(
                            (hour) => hour < now.hour(),
                          ),
                        disabledMinutes: (selectedHour) =>
                          selectedHour === now.hour()
                            ? Array.from({ length: 60 }, (_, i) => i).filter(
                                (minute) => minute < now.minute(),
                              )
                            : [],
                      };
                    }

                    return {};
                  }}
                />
              </Form.Item>
            </>
          )}
        </figure>
      </Form>
    </Modal>
  );
};
