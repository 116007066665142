import {
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Row,
  Select,
  TimePicker,
} from 'antd';
import { Modal } from 'components';
import dayjs from 'dayjs';
import { DiscountApiService } from 'features/discount-code/api/discount-api.service';
import { useCreateDiscountCode } from 'features/discount-code/use-cases/create-discount-code';
import { NotificationFilterFormValues } from 'features/notification/components/notification-filter-form/type';
import { useState } from 'react';
import { getDisabledMinutes } from 'utils/date';

const { useBreakpoint } = Grid;

export const AddDiscountCountModal = ({
  onClose,
  initialValues,
  handleFilter,
  filterForm,
}: {
  onClose: () => void;
  handleFilter: (values: NotificationFilterFormValues) => void;
  initialValues: NotificationFilterFormValues;
  filterForm: any;
}) => {
  const formName = 'add-discount-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();

  const [sportsData, setSportsData] = useState<any>(null); // Track 'Send & Later' action
  const [facilityData, setFacilityData] = useState<any>(null); // Track 'Send & Later' action
  const [packagesData, setPackagesData] = useState<any>(null);

  const [redirectVal, setRedirectVal] = useState<any>(null);
  const [couponType, setCouponType] = useState<any>(null);

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };

  const getSportsData = async (redValue: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: redValue === 'Booking' ? 'BookingSports' : 'PackageSports',
      } as any);
      setSportsData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getFacilityData = async (redirectLabel: string, sportsId: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: redirectLabel,
        sport_id: sportsId,
      } as any);
      setFacilityData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getPackagesOrFieldsData = async (facilityId: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag:
          redirectVal === 'Booking'
            ? 'FacilityWiseField'
            : 'FacilityWisePackage',
        facility_id: facilityId,
      } as any);
      setPackagesData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const { isLoading, mutate } = useCreateDiscountCode();
  const handleUpdate = async (formData: any) => {
    const updatedFormData = {
      ...formData,
      DiscountType: formData.DiscountTypeId === 1 ? 'Flat' : 'Percent',
      SlotStartTime:
        formData.ItemId && dayjs(formData.ItemId[0]).format('HH:mm'), // Format time as "13:00"
      SlotEndTime: formData.ItemId && dayjs(formData.ItemId[1]).format('HH:mm'), // Format time as "13:00"
      StartDate: dayjs(formData.StartDate).format('YYYY-MM-DD'), // Format date as "2024-12-05"
      EndDate: dayjs(formData.EndDate).format('YYYY-MM-DD'), // Format date as "2024-12-05"
      NoOfCoupons: +formData.NoOfCoupons,
      DiscountValue: +formData.DiscountValue,
    };

    delete updatedFormData.ItemId;

    mutate(updatedFormData, {
      onSuccess: (data: any) => {
        if (data.ResponseStatus === 1) {
          filterForm.resetFields();
          handleFilter({ ...initialValues });
          handleClose(); // Close modal or handle success state
        }
      },
      onError: (error: any) => {
        console.error('Error creating notification:', error); // Handle error
      },
    });
  };

  const handleKeyValueDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      '.',
      'Enter',
    ];

    const isNumber = /^[0-9]$/;

    if (!allowedKeys.includes(e.key) && !isNumber.test(e.key)) {
      e.preventDefault(); // Block non-allowed keys
    }

    // Prevent multiple decimals
    if (
      e.key === '.' &&
      (e.currentTarget.value.includes('.') || e.currentTarget.value === '')
    ) {
      e.preventDefault();
    }

    // Prevent typing more than two decimal places
    if (
      e.currentTarget.value.includes('.') &&
      e.currentTarget.value.split('.')[1].length >= 2 &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const handleKeyCouponDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
    ];
    const isNumber = /^[0-9]$/;

    // Allow navigation/control keys, prevent all others (including ".")
    if (!allowedKeys.includes(e.key) && !isNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateDates = (getFieldValue: (arg0: any) => any) => ({
    validator() {
      const startDate = getFieldValue('StartDate');
      const endDate = getFieldValue('EndDate');

      if (startDate && endDate && startDate.isAfter(endDate)) {
        return Promise.reject(
          new Error('End Date must be after or equal to Start Date'),
        );
      }

      return Promise.resolve();
    },
  });

  const handleRedirectionChange = (value: string) => {
    form.setFieldValue('CouponFor', value);
    setRedirectVal(value);
    getSportsData(value);
    form.resetFields(['SportId', 'FacilityId', 'ItemId']);
  };

  const handleSportsChange = (value: string) => {
    form.setFieldValue('SportId', value);
    form.resetFields(['FacilityId']);
    const reLabel =
      redirectVal === 'Booking' ? 'BookingFacility' : 'PackageFacility';
    getFacilityData(reLabel, value);
  };

  const handleFacilityChange = (value: string) => {
    form.setFieldValue('FacilityId', value);
    form.resetFields(['PackageId', 'FieldId']);
    getPackagesOrFieldsData(value);
  };

  const handlePackageChange = (value: string) => {
    form.setFieldValue('PackageId', value);
    form.resetFields(['ItemId']);
  };

  const handleFieldChange = (value: string) => {
    form.setFieldValue('FieldId', value);
    form.resetFields(['ItemId']);
  };

  const handleCouponTypeChange = (value: string) => {
    form.setFieldValue('DiscountTypeId', value);
    form.resetFields(['DiscountValue']);
    setCouponType(value);
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Generate New Coupon'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText={'Save'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
    >
      <Form
        className='form_padding'
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate as any}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='Coupon Type'
                name='CodeTypeId'
                rules={[
                  {
                    required: true,
                    message: 'Please select Coupon Type ',
                  },
                ]}
              >
                <Select
                  placeholder='Please select Coupon Type'
                  options={[
                    { value: 1, label: 'Automatic' },
                    { value: 2, label: 'Manual' },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const sportsValue = getFieldValue('CodeTypeId');
                  if (sportsValue === 1) {
                    return (
                      <Form.Item
                        label='Prefix'
                        name='Prefix'
                        rules={[
                          { required: true, message: 'Please enter Prefix' },
                        ]}
                      >
                        <Input
                          placeholder='please enter Prefix'
                          maxLength={2}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const sportsValue = getFieldValue('CodeTypeId');
                  if (sportsValue === 2) {
                    return (
                      <Form.Item
                        label='Coupon Code'
                        name='CouponCode'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Coupon Code',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder='Enter your Coupon Code' />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Name'
                name='Title'
                rules={[
                  {
                    required: true,
                    message: 'Please enter name',
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder='Enter your name' maxLength={54} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Limit Usage'
                name='NoOfCoupons'
                rules={[
                  { required: true, message: 'Please enter Limit Usage' },
                ]}
              >
                <Input
                  placeholder='Please enter Limit Usage'
                  onKeyDown={handleKeyCouponDown}
                  maxLength={5} // Optional: Prevent long inputs
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='From Date'
                name='StartDate'
                rules={[{ required: true, message: 'Please enter From Date' }]}
              >
                <DatePicker
                  format='DD-MM-YYYY'
                  disabledDate={(current) =>
                    current && current.isBefore(dayjs().startOf('day'))
                  }
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='To Date'
                name='EndDate'
                rules={[
                  {
                    required: true,
                    message: 'Please select To Date',
                  },
                  validateDates(form.getFieldValue),
                ]}
              >
                <DatePicker
                  format='DD-MM-YYYY'
                  disabledDate={(current) => {
                    const startDate = form.getFieldValue('StartDate');

                    return current && startDate && startDate.isAfter(current);
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Value Type'
                name='DiscountTypeId'
                rules={[
                  { required: true, message: 'Please select Value Type ' },
                ]}
              >
                <Select
                  placeholder='Please select Value Type'
                  options={[
                    { value: 1, label: 'Flat' },
                    { value: 2, label: 'Percent' },
                  ]}
                  onChange={handleCouponTypeChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Value'
                name='DiscountValue'
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter value',
                  },
                ]}
              >
                <Input
                  addonAfter={couponType === 2 ? '%' : null}
                  placeholder='Enter Value'
                  onKeyDown={handleKeyValueDown}
                  onChange={(e) => {
                    const value = e.target.value;
                    const discountVal = form.getFieldValue('DiscountValue');
                    if (discountVal && parseFloat(value) >= 100) {
                      form.setFieldsValue({ DiscountValue: '100' }); // Cap the value to 99.9
                    }
                  }}
                />
              </Form.Item>
            </Col>

            {/* Redirection Field */}
            <Col span={12}>
              <Form.Item
                label='Coupon For'
                name='CouponFor'
                rules={[
                  { required: true, message: 'Please select Coupon For' },
                ]}
              >
                <Select
                  allowClear
                  placeholder='Please select Discount For'
                  options={[
                    { value: 'Booking', label: 'Booking' },
                    { value: 'Package', label: 'Package' },
                  ]}
                  onChange={handleRedirectionChange}
                />
              </Form.Item>
            </Col>
            {/* sports id */}
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const redirectionValue = getFieldValue('CouponFor');
                  if (
                    redirectionValue === 'Package' ||
                    redirectionValue === 'Booking'
                  ) {
                    return (
                      <Form.Item
                        label='Sports'
                        name='SportId'
                        // rules={[
                        //   { required: true, message: 'Please select Sports' },
                        // ]}
                      >
                        <Select
                          showSearch // Enable search functionality
                          placeholder='Please select Sports'
                          options={sportsData?.items.map((item: any) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          filterOption={(input, option) =>
                            typeof option?.label === 'string' &&
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handleSportsChange}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Col>
            {/* Facility Field - Conditionally Rendered */}
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const sportsValue = getFieldValue('SportId');
                  if (sportsValue) {
                    return (
                      <Form.Item
                        label='Facility'
                        name='FacilityId'
                        // rules={[
                        //   { required: true, message: 'Please select Facility' },
                        // ]}
                      >
                        <Select
                          showSearch // Enable search functionality
                          placeholder='Please select Facility'
                          options={facilityData?.items.map((item: any) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          filterOption={(input, option) =>
                            typeof option?.label === 'string' &&
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handleFacilityChange}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Col>
            {/* Field - Conditionally Rendered for booking */}
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const facilityIdVal = getFieldValue('FacilityId');
                  if (redirectVal === 'Booking' && facilityIdVal) {
                    return (
                      <Form.Item
                        label='Felids'
                        name='FieldId'
                        // rules={[
                        //   { required: true, message: 'Please select Felids' },
                        // ]}
                      >
                        <Select
                          showSearch // Enable search functionality
                          placeholder='Please select Felids'
                          options={packagesData?.items.map((item: any) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          filterOption={(input, option) =>
                            typeof option?.label === 'string' &&
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handleFieldChange}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Col>
            {/* packages - Conditionally Rendered  for package*/}
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const facilityIdVal = getFieldValue('FacilityId');
                  if (redirectVal === 'Package' && facilityIdVal) {
                    return (
                      <Form.Item label='Package' name='PackageId'>
                        <Select
                          showSearch // Enable search functionality
                          placeholder='Please select Package'
                          options={packagesData?.items.map((item: any) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          filterOption={(input, option) =>
                            typeof option?.label === 'string' &&
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handlePackageChange}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                }}
              </Form.Item>
            </Col>
            {/* is slot - Conditionally Rendered */}
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const facilityIdVal = getFieldValue('FieldId');
                if (redirectVal === 'Booking' && facilityIdVal) {
                  return (
                    <Form.Item
                      label='Slots'
                      name='ItemId'
                      // rules={[{ required: true, message: 'Please select Package' }]}
                    >
                      <TimePicker.RangePicker
                        size='large'
                        style={{ width: '100%' }}
                        format='h mm A'
                        showSecond={false}
                        inputReadOnly={true}
                        hideDisabledOptions={true}
                        disabledTime={getDisabledMinutes}
                        order={false}
                      />
                    </Form.Item>
                  );
                }

                return null;
              }}
            </Form.Item>
          </Row>
        </figure>
      </Form>
    </Modal>
  );
};
