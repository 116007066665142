import { PlusOutlined } from '@ant-design/icons';
import { Empty, Form, message, Spin, Typography } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { SocialApiService } from 'features/social-match/api/social-api.service';
import li3ibicon from 'features/social-match/components/image/circle.svg';
import { useGetSocialdetails } from 'features/social-match/use-cases/get-socialdetail';
import { useUpdateSocial } from 'features/social-match/use-cases/update.social';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';

import { SocialInfoEditDetails } from './social-info-update';
export const SocialViewModal = ({
  onClose,
  id,
  onSuccess,
}: {
  onClose: () => void;
  id: string;
  onSuccess: any;
}) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const userQueryResult = useGetSocialdetails(id, {
    cacheTime: 0,
  });
  const [score, setScore] = useState<any>([]);
  const formName = 'edit-user-form';
  const [form] = Form.useForm();
  const {
    isLoading: isLoadingGetSocial,
    isFetching: isFetchingGetSocial,
    data: SocailData,
    isError: isErrorLabel,
  } = userQueryResult;
  const isLoading = {
    loading: isLoadingGetSocial || isFetchingGetSocial,
    isLoadingGetSocial,
  };
  const initial = async () => {
    try {
      const apires = await SocialApiService().getSocialMatchScore(id);
      setScore(apires);
    } catch (error) {
      message.error(error as any);
    }
  };
  useEffect(() => {
    initial();
  }, []);

  const { mutate: mutateUpdateQuestion } = useUpdateSocial();
  const handleUpdate = (formData: any) => {
    const {
      team_a_score,
      team_b_score,
      team_a_score1,
      team_b_score2,
      team_a_score2,
      team_b_score3,
    } = formData;
    const payload: any = { match_id: id, score: [] };

    if (team_a_score !== '' || team_b_score !== '') {
      payload.score.push({ set_no: 1, team_a_score, team_b_score });
    }

    if (team_a_score1 !== '' || team_b_score2 !== '') {
      payload.score.push({
        set_no: 2,
        team_a_score: team_a_score1,
        team_b_score: team_b_score2,
      });
    }

    if (team_a_score2 !== '' || team_b_score3 !== '') {
      payload.score.push({
        set_no: 3,
        team_a_score: team_a_score2,
        team_b_score: team_b_score3,
      });
    }
    if (!formData.team_a_score) {
      message.error('Please enter Field');

      return;
    }
    mutateUpdateQuestion(payload, { onSuccess });
  };

  useEffect(() => {
    let teamAScore = '';
    let teambScore = '';
    let teamAScore1 = '';
    let teamBScore1 = '';
    let teamAScore2 = '';
    let teamBScore2 = '';

    score?.score_list?.forEach((res: any) => {
      if (res.set_no === 1) {
        teamAScore = res.team_a_score || 0;
        teambScore = res.team_b_score || 0;
      }
    });
    score?.score_list?.forEach((res: any) => {
      if (res.set_no === 2) {
        teamAScore1 = res.team_a_score || 0;
        teamBScore1 = res.team_b_score || '';
      }
    });
    score?.score_list?.forEach((res: any) => {
      if (res.set_no === 3) {
        teamAScore2 = res.team_a_score || 0;
        teamBScore2 = res.team_b_score || 0;
      }
    });
    form.setFieldValue('team_a_score', teamAScore);
    form.setFieldValue('team_b_score', teambScore);
    form.setFieldValue('team_a_score1', teamAScore1);
    form.setFieldValue('team_b_score2', teamBScore1);
    form.setFieldValue('team_a_score2', teamAScore2);
    form.setFieldValue('team_b_score3', teamBScore2);
  }, [score]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='modal_social right-modal mtch_dtls_modal mtch_dtail'
        open
        centered
        title={t('social.list.heading')}
        onCancel={() => {
          document.body.style.overflow = 'none';
          onClose();
        }}
        style={{ width: '670px' }}
        getContainer={false}
        onOk={form.submit}
        footer={null}
        // okText={t('social.update.updatescore')}
        okText={
          SocailData?.match_status === 'Live' ||
          SocailData?.match_status === 'Waiting Result'
            ? t('social.update.updatescore')
            : undefined
        }
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
          style: {
            display:
              SocailData?.match_status === 'Live' ||
              SocailData?.match_status === 'Waiting Result'
                ? 'block'
                : 'none',
          }, // Hide or display based on condition
        }}
      >
        {isLoadingGetSocial ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <div className='sm_pndl'>
              {/* <div className='smpnm'>
                <span className='media'>
                  <img src={SocailData.sport_icon_link} />
                </span>
                <span className='smttl'>{SocailData.sport_name}</span>
              </div> */}
              <div className='sm_mttl'>
                <div className='dtl_pr'>
                  <div className='media'>
                    <img src={SocailData.image ?? li3ibicon} />
                  </div>
                  <div className='smpnttl'>
                    {/* <h3>{SocailData.company_name}</h3> */}
                    <h3>{SocailData.sport_name}</h3>
                    <p>{SocailData.field_name}</p>
                    <p>{SocailData.facility_name}</p>
                  </div>
                </div>
                <div className='sm_dtl'>
                  <span>
                    {moment(SocailData.start_date).format('ll')}{' '}
                    {formatDate({
                      date: SocailData.start_date,
                      format: 'time',
                      timeZone: DEFAULT_TIMEZONE,
                    })}
                  </span>{' '}
                  -
                  {formatDate({
                    date: SocailData.end_date,
                    format: 'time',
                    timeZone: DEFAULT_TIMEZONE,
                  })}
                  {/* <span>{moment(SocailData.end_date)}</span> */}
                  <p>
                    <span
                      className={`${
                        SocailData.match_status === 'Created'
                          ? 'ttlp_wr'
                          : 'ttlp_lv'
                      }`}
                    >
                      {SocailData.match_status}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className='plsection'>
              <div className='head'>
                <h3>Joined Players</h3>
                <label htmlFor=''>{SocailData.level_title}</label>
              </div>
              {SocailData.match_status_id === 1 ||
              SocailData.match_status_id === 4 ||
              SocailData.match_status_id === 6 ||
              SocailData?.match_status_id === 100 ? (
                <>
                  {SocailData.total_player === 2 && (
                    <div className='plr_list plr_list02'>
                      <div className='cell'>
                        {/* Render player from team A */}
                        {SocailData?.player_list.map(
                          (res: any, index: number) => {
                            if (res.team === 'A') {
                              return (
                                <div className='cellsub' key={index}>
                                  <div className='media'>
                                    <img
                                      src={
                                        res.image_url ||
                                        'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                                      }
                                      alt=''
                                    />
                                  </div>
                                  <h3>{res?.player_name}</h3>
                                </div>
                              );
                            }

                            return null;
                          },
                        )}
                        {!SocailData?.player_list.some(
                          (res: any) => res.team === 'A',
                        ) && (
                          <div className='add_bydef'>
                            <PlusOutlined />
                          </div>
                        )}
                      </div>
                      <div className='cell'>
                        {/* Render player from team B */}
                        {SocailData?.player_list.map(
                          (res: any, index: number) => {
                            if (res.team === 'B') {
                              return (
                                <div className='cellsub' key={index}>
                                  <div className='media'>
                                    <img
                                      src={
                                        res.image_url ||
                                        'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                                      }
                                      alt=''
                                    />
                                  </div>
                                  <h3>{res?.player_name}</h3>
                                </div>
                              );
                            }

                            return null;
                          },
                        )}
                        {/* If there's no player from team B, render a plus icon */}
                        {!SocailData?.player_list.some(
                          (res: any) => res.team === 'B',
                        ) && (
                          <div className='add_bydef'>
                            <PlusOutlined />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {SocailData?.match_status_id === 1 ||
              SocailData?.match_status_id === 4 ||
              SocailData?.match_status_id === 6 ||
              SocailData?.match_status_id == 100 ? (
                <>
                  {SocailData.total_player === 4 && (
                    <div className='plr_list'>
                      <div className='cell'>
                        {SocailData?.player_list.map(
                          (res: any, index: number) => {
                            // 3
                            if (res.team === 'A' && index < 4) {
                              return (
                                <div className='cellsub' key={index}>
                                  <div className='media'>
                                    <img
                                      src={
                                        res.image_url ||
                                        'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                                      }
                                      alt=''
                                    />
                                  </div>
                                  <h3>{res?.player_name}</h3>
                                </div>
                              );
                            }

                            return null;
                          },
                        )}
                        {SocailData?.player_list.filter(
                          (res: any) => res.team === 'A',
                        ).length === 0 && (
                          <>
                            <div className='add_bydef'>
                              <PlusOutlined />
                            </div>
                            <div className='add_bydef'>
                              <PlusOutlined />
                            </div>
                          </>
                        )}
                        {/* If there is only one player in team B, render one plus icon */}
                        {SocailData?.player_list.filter(
                          (res: any) => res.team === 'A',
                        ).length === 1 && (
                          <div className='add_bydef'>
                            <PlusOutlined />
                          </div>
                        )}
                      </div>
                      {/* Old code  */}
                      {/* New code */}
                      <div className='cell'>
                        {SocailData?.player_list.map(
                          (res: any, index: number) => {
                            if (res.team === 'B') {
                              return (
                                <div className='cellsub' key={index}>
                                  <div className='media'>
                                    <img
                                      src={
                                        res.image_url ||
                                        'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                                      }
                                      alt=''
                                    />
                                  </div>
                                  <h3>{res?.player_name}</h3>
                                </div>
                              );
                            }

                            return null;
                          },
                        )}
                        {SocailData?.player_list.filter(
                          (res: any) => res.team === 'B',
                        ).length === 0 && (
                          <>
                            <div className='add_bydef'>
                              <PlusOutlined />
                            </div>
                            <div className='add_bydef'>
                              <PlusOutlined />
                            </div>
                          </>
                        )}
                        {SocailData?.player_list.filter(
                          (res: any) => res.team === 'B',
                        ).length === 1 && (
                          <div className='add_bydef'>
                            <PlusOutlined />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              className='mtch_dtl_frm'
              // initialValues={initialValues}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              <SocialInfoEditDetails SocailData={SocailData} form={form} />
            </Form>
            <div className='jn_req'>
              <div className='join_ttl'>
                <Title level={2}>
                  Request ({SocailData?.join_request_list?.length})
                </Title>
              </div>
              <div className='rqst_wrap'>
                {Array.isArray(SocailData?.join_request_list) &&
                SocailData?.join_request_list?.length ? (
                  SocailData?.join_request_list.map(
                    (item: any, index: number) => {
                      return (
                        <div className='rqst_list' key={index}>
                          <div className='reqst_bx'>
                            <div className='media'>
                              <img
                                src={
                                  item?.image_url ||
                                  'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                                }
                                alt=''
                              />
                            </div>
                            <div className='rqst_dtls'>
                              <span>{item?.player_name}</span>
                              <span
                                style={{
                                  color:
                                    item?.request_type === 'Requested'
                                      ? '#FF6100'
                                      : item?.request_type === 'Invited'
                                      ? 'green'
                                      : '#ff0000',
                                }}
                              >
                                {item?.request_type}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )
                ) : (
                  <div className='no_dataemty'>
                    <Empty />
                  </div>
                )}
              </div>
            </div>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
