import { MoreOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Image,
  MenuProps,
  Space,
  Switch,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { OfferFilterFormPaginated } from 'features/discount-code/components/filter-form/offer-filter-form/type';
import { useStatusOffer } from 'features/discount-code/use-cases/status-update-offer';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Notifications } from 'types/notification';
type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: OfferFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<OfferFilterFormPaginated>>;
  refetch: () => void;
};

export const OfferTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissions();

  // const { mutate: deleteMutate } = useDeleteNotification();
  const { mutate } = useStatusOffer();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  // const handleDelete = (Id: string) => {
  //   const formData = {
  //     id: Id,
  //   };
  //   deleteMutate(formData, { onSuccess: refetch }); // Assuming mutate is defined elsewhere
  // };

  const items = (record: any): MenuProps['items'] => {
    const menuItems = [
      {
        label: 'Edit',
        key: '0',
        onClick: () => {
          navigate(getRoute(ROUTES.DISCOUNT_CODE_OFFER_EDIT, record.Id)); // Navigate to edit route
          document.body.style.overflow = 'hidden';
        },
      },
      // {
      //   label: 'Delete',
      //   key: '1',
      //   // onClick: () => handleDelete(record.Id),
      // },
    ];

    return menuItems;
  };

  if (!permissions) return null;

  const handleUpdateStatus = (id: number, checked: boolean) => {
    mutate({ id: id, Flag: 'ChangeStatus', IsActive: checked });
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge text={'Offer Found'} count={data?.total || 0} />
      </StyledTableHeader>
      <Table<Notifications>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='Id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
      >
        <Column<Notifications>
          title={'Title'}
          dataIndex={'Title'}
          // width={screens.xxl ? 'auto' : 170}
        />

        <Column<Notifications>
          title={'Title Ar'}
          dataIndex={'Title_Ar'}
          // width={screens.xxl ? 'auto' : 170}
        />

        <Column<Notifications>
          title={'Description'}
          dataIndex={'Description'}
          ellipsis
          // width={screens.xxl ? 'auto' : 170}
        />

        <Column<Notifications>
          title={'Description Ar'}
          dataIndex={'Description_Ar'}
          ellipsis
          // width={screens.xxl ? 'auto' : 170}
        />

        {/* <Column<Notifications>
          width={200}
          title={'Date From'}
          dataIndex={'StartDate'}
          render={(_, record: any) => (
            <span>
              {record.StartDate &&
                dayjs(record.StartDate).format('DD-MM-YYYY hh:mm A')}
            </span>
          )}
        /> */}

        <Column<Notifications>
          width={150}
          title={'Image'}
          dataIndex={'ImageUrl'}
          render={(_, record: any) => (
            <>
              <Image src={record.ImageUrl} fallback='' width={40} height={40} />
            </>
          )}
        />

        <Column<Notifications>
          title={'Redirection Type'}
          dataIndex={'RedirectionType'}
          render={(_, record: any) => (
            <span>
              {record.RedirectionType ? record.RedirectionType : 'N/A'}
            </span>
          )}
        />

        <Column<Notifications>
          title={'Status'}
          dataIndex={'IsActive'}
          render={(_, record: any) => (
            <span>{record.IsActive ? 'Active' : 'InActive'}</span>
          )}
        />

        <Column<Notifications>
          // width={80}
          title={t('package.table.action')}
          dataIndex={'last_transaction_date'}
          // className='action_col'
          render={(_, record: any) => (
            <Space>
              <Tooltip title='Is Active'>
                <Switch
                  defaultChecked={record.IsActive}
                  checked={record.IsActive}
                  onChange={(isChecked) => {
                    handleUpdateStatus(record.Id, isChecked);
                  }}
                />
              </Tooltip>
              <Dropdown menu={{ items: items(record) }} trigger={['click']}>
                <Space className='dot_icon_drop' style={{ cursor: 'pointer' }}>
                  <MoreOutlined style={{ fontWeight: '600' }} />
                </Space>
              </Dropdown>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
