import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum OfferBannerFilterFormField {
  BannerTypeId = 'BannerTypeId',
  title = 'Title',
  Date = 'date',
  status = 'IsActive',
}
export type OfferBannerFilterFormValues = {
  [OfferBannerFilterFormField.BannerTypeId]: number | undefined;
  [OfferBannerFilterFormField.title]: string | undefined;
  [OfferBannerFilterFormField.Date]:
    | [Dayjs | undefined, Dayjs | undefined]
    | null;
  [OfferBannerFilterFormField.status]: string | undefined;
};

export type OfferBannerFilterFormPaginated = OfferBannerFilterFormValues &
  PaginatedRequestParams;
