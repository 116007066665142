import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentTopBar } from 'components';
import { AddOfferModal } from 'features/discount-code/components/modal/add-offer-modal';
import { useState } from 'react';
import { CompanyType } from 'types';
const { useBreakpoint } = Grid;

export const OfferTopBar = ({ initialValues, handleFilter, form }: any) => {
  useState<CompanyType>();
  const screens = useBreakpoint();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ContentTopBar
        title={'Offer'}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => {
                setShowModal(true);
                document.body.style.overflow = 'hidden';
              }}
            >
              Add Offer
            </Button>
            {showModal && (
              <AddOfferModal
                onClose={() => setShowModal(false)}
                initialValues={initialValues}
                handleFilter={handleFilter}
                filterForm={form}
              />
            )}
          </>
        }
      />
    </>
  );
};
