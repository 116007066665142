import { Form, Grid } from 'antd';
import { Modal } from 'components';
import {
  LabelFormField,
  LabelFormValues,
} from 'features/social-label/components/interface/type';
import { useCreateLabel } from 'features/social-label/use-cases/create-label';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormValues } from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialInfoFormItems } from './social-info-form-items';
const { useBreakpoint } = Grid;
export const AddSocialLabelModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);

  const { isLoading, mutate } = useCreateLabel();

  const getInitialValues = {
    [LabelFormField.title]: undefined,
    [LabelFormField.description]: undefined,
  };

  const handleUpdate = (formData: LabelFormValues) => {
    if (isUpdating) return; // If already updating, prevent further updates
    setIsUpdating(true); // Set updating state to true

    mutate(formData, {
      onSuccess: () => {
        onClose();
        setTimeout(() => {
          setIsUpdating(false);
          window.location.reload();
        }, 1000);
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal cstmmdlright'
        title={t('social.label.addlabel')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('social.formfield.addnewlabel')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate as any}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <SocialInfoFormItems form={form} />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
