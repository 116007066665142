import { NotificationDetailsModal } from 'features/notification/components/modal/notification-details-modal-items';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const NotificationitemDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.NOTIFICATION);
  };

  return <NotificationDetailsModal id={id} onClose={closeModal} />;
};
