import { Form } from 'antd';
import { ContentFilters } from 'components';
import dayjs from 'dayjs';
import { OfferBannerTopBar } from 'features/discount-code//components/top-bar/offer-banner-top';
import { OfferBannerFilterForm } from 'features/discount-code/components/filter-form/offer-banner-filter-form/offer-banner-filter-form';
import {
  OfferBannerFilterFormPaginated,
  OfferBannerFilterFormValues,
} from 'features/discount-code/components/filter-form/offer-banner-filter-form/type';
import { OfferBannerTable } from 'features/discount-code/components/offer-banner-table/offer-banner-table';
import { useGetOfferBannerList } from 'features/discount-code/use-cases/get-offer-banner-list';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { formatDate } from 'utils/date';

const initialFilterValues: OfferBannerFilterFormValues = {
  BannerTypeId: 1,
  Title: undefined,
  date: [undefined, undefined],
  IsActive: undefined,
};

export const OfferBannerListView = () => {
  const { params, updateParams, resetPage } =
    usePaginationParams<OfferBannerFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const preparePayload = (paramsw: OfferBannerFilterFormPaginated) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];

    return {
      ...omit(params, ['date']),
      FromDate: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      ToDate: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
    };
  };
  const { data, isFetching, refetch } = useGetOfferBannerList(
    preparePayload(params),
  );
  const handleFiltersChange = (values: OfferBannerFilterFormValues) => {
    updateParams(values);
    resetPage();
  };
  const formId = 'offer-banner-filters-form';
  const [form] = Form.useForm<OfferBannerFilterFormValues>();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/discount-code/banner') {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [location]);

  return (
    <div>
      <OfferBannerTopBar
        refetch={refetch}
        form={form}
        resetPage={resetPage}
        handleFilter={handleFiltersChange}
        initialValues={initialFilterValues}
      />
      <ContentFilters formId={formId}>
        <OfferBannerFilterForm
          form={form}
          formId={formId}
          handleFilter={handleFiltersChange as any}
          initialValues={initialFilterValues as any}
        />
      </ContentFilters>
      <OfferBannerTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
      />

      <Outlet />
    </div>
  );
};
