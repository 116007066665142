import { Col, Form, Grid, Image, message, Row } from 'antd';
import { Modal } from 'components';
import dayjs from 'dayjs';
import { NotificationApiService } from 'features/notification/api/notification-api.service';
import { useCreateNotificationSend } from 'features/notification/use-cases/create-notification-send';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;

export const NotificationDetailsModal = ({
  onClose,
  id,
}: {
  onClose: () => void;
  id: any;
}) => {
  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const { mutate } = useCreateNotificationSend();
  const [notificationValue, setNotificationValue] = useState<any>('');
  const [isSend, setIsSend] = useState<boolean>(false);
  // Fetch notification details to populate the form
  const rowStyle = { marginBottom: '16px' }; // Add spacing between rows
  // const labelStyle = {
  //   fontSize: '16px', // Increase label font size
  //   fontWeight: 'bold', // Make labels bold
  //   display: 'block', // Ensure labels take full width
  //   marginBottom: '8px', // Add space below the label
  // };
  const handlesend = () => {
    const formData = { id: id };
    mutate(formData, {
      onSuccess: () => {
        handleClose(); // Assuming onClose is a function to close the modal or perform other actions
      },
    });
  };
  const intialsDeatils = async () => {
    try {
      const apiRes = await NotificationApiService().getNotificationdetails({
        id: id,
      } as any);
      // Set form values based on fetched details
      setNotificationValue(apiRes);
      setIsSend(apiRes?.items?.length > 0 && apiRes?.items[0]?.IsSend);
    } catch (error: any) {
      message.error(error);
    }
  };
  const stripHtmlTags = (html: string) => {
    if (!html) return '';

    return html.replace(/<[^>]*>?/gm, '');
  };
  useEffect(() => {
    intialsDeatils(); // Fetch details when the component mounts
  }, []);

  return (
    <Modal
      open
      centered
      className='right-modal dtls_notify'
      title='Detail Notification'
      onCancel={handleClose}
      getContainer={false}
      onOk={isSend ? undefined : form.submit}
      okText={isSend ? undefined : 'Send'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
      footer={isSend ? null : undefined}
    >
      <Form form={form} layout='vertical' onFinish={handlesend}>
        <Row gutter={[16, 16]}>
          <Col span={24} style={rowStyle}>
            <label>Device Type</label>
            <p className='data_bx'>
              {notificationValue?.items?.length > 0 &&
                notificationValue?.items[0]?.DeviceType}
            </p>
          </Col>

          <Col span={24} style={rowStyle}>
            {<label>Title</label>}

            <div className='data_bx'>
              <p>
                {notificationValue?.items?.length > 0 &&
                  notificationValue?.items[0]?.Title}
              </p>
            </div>
          </Col>

          <Col span={24} style={rowStyle}>
            <div className='arb_notify'>
              <div className='arb_dtls'>
                {<label>Message</label>}
                <div className='data_bx'>
                  <p>
                    {notificationValue?.items?.length > 0
                      ? stripHtmlTags(notificationValue.items[0].Body)
                      : 'No Message'}
                  </p>

                  <div className='arb_img'>
                    {/* <label style={labelStyle}>Image:</label> */}
                    <Image
                      src={
                        notificationValue?.items?.length > 0
                          ? notificationValue.items[0].Image
                          : 'default_image_url'
                      }
                      // width={50}
                      // height={50}
                      fallback='https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {notificationValue?.items?.length > 0 &&
            notificationValue?.items[0]?.ScheduleTime && (
              <Col span={24} style={rowStyle}>
                <label>Schedule Time</label>
                <div className='data_bx'>
                  <p>
                    {dayjs(notificationValue.items[0].ScheduleTime).format(
                      'DD-MM-YYYY hh:mm A',
                    )}
                  </p>
                </div>
              </Col>
            )}

          {notificationValue?.items?.length > 0 &&
            notificationValue?.items[0]?.RedirectionType && (
              <Col span={24} style={rowStyle}>
                <label>Redirection Type</label>
                <p className='data_bx'>
                  {notificationValue?.items[0]?.RedirectionType}
                </p>
              </Col>
            )}

          {notificationValue?.items?.length > 0 &&
            notificationValue?.items[0]?.SportName && (
              <Col span={24} style={rowStyle}>
                <label>Sports</label>
                <p className='data_bx'>
                  {notificationValue?.items[0]?.SportName}
                </p>
              </Col>
            )}

          {notificationValue?.items?.length > 0 &&
            notificationValue?.items[0]?.FacilityName && (
              <Col span={24} style={rowStyle}>
                <label>Facility Type</label>
                <p className='data_bx'>
                  {notificationValue?.items[0]?.FacilityName}
                </p>
              </Col>
            )}

          {notificationValue?.items?.length > 0 &&
            notificationValue?.items[0]?.ItemName && (
              <Col span={24} style={rowStyle}>
                <label>Package</label>
                <p className='data_bx'>
                  {notificationValue?.items[0]?.ItemName}
                </p>
              </Col>
            )}

          {notificationValue?.items?.length > 0 && (
            <Col span={24} style={rowStyle}>
              <label>Page</label>
              <p className='data_bx'>
                {notificationValue?.items[0]?.IsSlot ? 'Slot' : 'Detail'}
              </p>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
