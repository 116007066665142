import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export const DashboardApiService = () => {
  // const baseURL = process.env.REACT_APP_BASE_V2_URL;
  const getDashBoard = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `admin/dashboard/wallet`,
      params,
    });

    return response.data;
  };

  return {
    getDashBoard,
  };
};
