import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum NotificationFilterFormField {
  notificationtypeid = 'notificationtypeid',
  devicetypeid = 'devicetypeid',
  title = 'title',
  Date = 'date',
}
export type NotificationFilterFormValues = {
  [NotificationFilterFormField.notificationtypeid]: string | undefined;
  [NotificationFilterFormField.devicetypeid]: string | undefined;
  [NotificationFilterFormField.title]: string | undefined;
  [NotificationFilterFormField.Date]:
    | [Dayjs | undefined, Dayjs | undefined]
    | null;
};

export type NotificationFilterFormPaginated = NotificationFilterFormValues &
  PaginatedRequestParams;
