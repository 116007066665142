import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UpdateLabelDTO } from 'features/social-label/api/label-api.service';
import {
  LabelFormField,
  LabelFormValues,
} from 'features/social-label/components/interface/type';
import { useGetLabeldetails } from 'features/social-label/use-cases/get-labeldetails';
import { useUpdateLabel } from 'features/social-label/use-cases/label.update';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialInfoFormItems } from './social-info-form-items';
export const EditLabelModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [form] = Form.useForm();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  const { t } = useTranslation();
  const userQueryResult = useGetLabeldetails(`id=${id}`, {
    cacheTime: 0,
  });
  const {
    isLoading: isLoadingGetLabel,
    isFetching: isFetchingGetLabel,
    data: labelData,
    isError: isErrorLabel,
  } = userQueryResult;
  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateLabel } =
    useUpdateLabel();
  const isLoading = {
    loading: isLoadingGetLabel || isFetchingGetLabel || isLoadingUpdateLabel,
    isLoadingGetLabel,
    isLoadingUpdateLabel,
  };
  const handleUpdate = (formData: LabelFormValues) => {
    const payload: UpdateLabelDTO = {
      id: id,
      title: formData.title,
      description: formData.description,
      title_arabic: formData.title_arabic,
      description_arabic: formData.description_arabic,
    };
    mutateUpdateQuestion(payload as any, { onSuccess });
  };
  const getInitialValues = () => ({
    [LabelFormField.title]: labelData?.items[0].title || '',
    [LabelFormField.description]: labelData?.items[0].description || '',
    [LabelFormField.title_arabic]: labelData?.items[0].title_arabic || '',
    [LabelFormField.description_arabic]:
      labelData?.items[0].description_arabic || '',
  });

  const initialValues = getInitialValues();

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal cstmmdlright'
        title={t('social.UpdateLabel')}
        onCancel={() => {
          onClose(); // Call onClose function
        }}
        getContainer={false}
        onOk={form.submit}
        okText={t('social.UpdateLabel')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetLabel ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={initialValues}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              <SocialInfoFormItems form={form} />
              {/* <PasswordFormItems title={t('adminUsers.accountInfo')} /> */}
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
