/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Grid,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  // TimePicker,
  Upload,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import dayjs, { Dayjs } from 'dayjs';
import { AmenitiesSelect } from 'features/amenities/components';
import {
  FieldDetailsField,
  FieldDetailsFormValues,
  genderOptions,
  getTeamSizeOptions,
  sportTypeOptions,
  TimePriceInput,
  TimePriceModalFields,
  timeSlotsOptions,
  typeOptions,
} from 'features/fields';
import { multiPleExceptionalTimeRange } from 'features/fields/components/add-field-form/types';
import { ExceptionTimePriceInput } from 'features/fields/components/time-price-input/exception-time-price-input';
import { SportSelect } from 'features/sports';
import { canEditField, useUserContext } from 'features/users';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { AllDays, TimePrice } from 'types';
import { Company, Facility, Field, FieldCategory } from 'types';
import { Amenity } from 'types/amenity';
// import { getDisabledMinutes } from 'utils/date';
import { getOptions } from 'utils/get-options';

import {
  ImageGroupWrapper,
  ImageWrapper,
  StyledImageDeleteButton,
} from './field-details-form.styles';

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

type FieldDetailsFormProps = {
  fieldData: Field;
  facilityData: Facility;
  companyData: Company;
  disabled: boolean;
  handleDeleteImage: () => void;
  handleDeleteGalleryImage: (url: string) => void;
  handleUpdate: (
    object: FieldDetailsFormValues,
    FileList: RcFile[],
    galleryFileList: RcFile[],
  ) => void;
};

// type chipStateT = {
//   label: string;
// };

export const FieldDetailsForm = ({
  fieldData,
  facilityData,
  companyData,
  disabled,
  handleDeleteImage,
  handleDeleteGalleryImage,
  handleUpdate,
}: FieldDetailsFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<FieldDetailsFormValues>();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const screens = useBreakpoint();
  const { fileList, uploadProps } = useUploadProps();
  const { fileList: galleryFileList, uploadProps: uploadGalleryProps } =
    useUploadProps();
  const [timePriceError] = useState<boolean>(false);

  const size = Number.MAX_SAFE_INTEGER;

  const [isFormDirty, setIsFormDirty] = useState(false);
  const multiExceptionalValue_ = [
    {
      exceptionDays: [],
      exceptionTimeRange: [],
      exceptionWeekDays: [],
    },
  ];

  const [mulitExcepTimeRange_, setMulitExcepTimeRange_] = useState<
    multiPleExceptionalTimeRange[]
  >(multiExceptionalValue_);

  const timeSlot = Form.useWatch(FieldDetailsField.TimeSlot, form);
  const openingHours = Form.useWatch(FieldDetailsField.OpeningHours, form);

  useEffect(() => {
    exceptionDataModify(fieldData.exception_ranges);
  }, []);

  const exceptionDataModify = (value: any) => {
    const exceptionAddToData_ = (
      start_time: string,
      end_time: string,
      slot_price: number,
    ) => {
      if (start_time !== null && end_time !== null && slot_price !== null) {
        return {
          [TimePriceModalFields.ExceptionDaysRange]: [
            dayjs(start_time, 'HH:mm:ss'),
            dayjs(end_time, 'HH:mm:ss'),
          ],
          [TimePriceModalFields.ExceptionDaysSlot]: slot_price,
        };
      }
    };

    const exceptionDateRange = (start_date: Date, end_date: Date) => {
      if (start_date !== null && end_date !== null) {
        return [dayjs(start_date, 'YYYY-MM-DD'), dayjs(end_date, 'YYYY-MM-DD')];
      }
    };

    const exceptionDataResponse: any = [];

    value.map((data: any) => {
      if (data === undefined && data.length === 0) {
        return true;
      }
      const expRange = exceptionDateRange(data?.start_date, data?.end_date);
      const expTRange: any = [];
      const expWeek: any[] = [];
      if (data && data.weekdays) {
        data.weekdays.forEach((res: any) => {
          expWeek.push(res);
        });
      }

      data?.time_ranges?.forEach((time_range: any) => {
        expTRange.push(
          exceptionAddToData_(
            time_range?.start_time,
            time_range?.end_time,
            time_range.slot_price,
          ),
        );
      });
      exceptionDataResponse.push({
        exceptionDays: expRange,
        exceptionTimeRange: expTRange,
        exceptionWeekDays: expWeek,
      });
    });

    setMulitExcepTimeRange_(exceptionDataResponse);
  };

  if (!user) return null;

  const getChargeableAmenities = fieldData.amenities.filter(
    (amenity: Amenity) => amenity.chargeable === true,
  );
  const getFreeAmenities = fieldData.amenities.filter(
    (amenity: Amenity) => amenity.chargeable === false,
  );
  const isFemaleOnly = fieldData.categories.includes(FieldCategory.FemaleOnly);
  const isMaleOnly = fieldData.categories.includes(FieldCategory.MaleOnly);
  const getCategoriesType = fieldData.categories.find(
    (category) => category === FieldCategory.Indoor || FieldCategory.Outdoor,
  );
  const getOpeningHours = [
    dayjs(fieldData.open_from, 'HH:mm:ss'),
    dayjs(fieldData.open_to, 'HH:mm:ss'),
  ];

  const convertStringToDate = (stringTime: string): Dayjs => {
    const timeSplit = stringTime.split(':');

    return dayjs().hour(+timeSplit[0]).minute(+timeSplit[1]);
  };

  const getTimePrice = (): TimePrice => {
    const data: AllDays[] = [];
    const expDay: any = [];
    const expDate: any = [];

    const addToData = (
      fieldId: string,
      timeRangeId: string,
      start_time: string,
      end_time: string,
      slot_price: number,
    ) => {
      if (start_time !== null && end_time !== null && slot_price !== null) {
        data.push({
          [TimePriceModalFields.AllDaysRange]: [
            convertStringToDate(start_time),
            convertStringToDate(end_time),
          ],
          [TimePriceModalFields.AllDaysSlot]: slot_price,
          fieldId,
          timeRangeId,
        });
      }
    };

    const exceptionAddToData = (
      start_time: string,
      end_time: string,
      slot_price: number,
    ) => {
      if (start_time !== null && end_time !== null && slot_price !== null) {
        expDay.push({
          [TimePriceModalFields.ExceptionDaysRange]: [
            dayjs(start_time, 'HH:mm:ss'),
            dayjs(end_time, 'HH:mm:ss'),
          ],
          [TimePriceModalFields.ExceptionDaysSlot]: slot_price,
        });
      }
    };

    const exceptionDateRange = (start_date: Date, end_date: Date) => {
      if (start_date !== null && end_date !== null) {
        expDate.push(
          dayjs(start_date, 'YYYY-MM-DD'),
          dayjs(end_date, 'YYYY-MM-DD'),
        );
      }
    };

    fieldData?.time_ranges?.forEach((time_range: any) => {
      addToData(
        time_range?.field_id,
        time_range?.id,
        time_range?.start_time,
        time_range?.end_time,
        time_range.slot_price,
      );
    });

    fieldData?.exception_ranges?.forEach((exData: any) => {
      exceptionDateRange(exData?.start_date, exData?.end_date);
      //expDate.push(dayjs(exData.start_date,'YYYY-mm-dd'),dayjs(exData.end_date,'YYYY-mm-dd'));
      // expDate.push(dayjs(exData.start_date),"2024-02-12");
      exData?.time_ranges?.forEach((time_range: any) => {
        exceptionAddToData(
          time_range?.start_time,
          time_range?.end_time,
          time_range.slot_price,
        );
      });
    });

    return {
      [TimePriceModalFields.AllDays]: data,
      [TimePriceModalFields.ExceptionDays]: expDay,
      [TimePriceModalFields.ExceptionDaysList]: expDate,
    };
  };
  const getInitialValues = {
    [FieldDetailsField.Company]: companyData.name,
    [FieldDetailsField.Facility]: facilityData.name,
    [FieldDetailsField.Area]: facilityData.area.name,
    [FieldDetailsField.Name]: fieldData.name,
    [FieldDetailsField.NameArabic]: fieldData.name_arabic,
    [FieldDetailsField.Rank]: fieldData.rank,
    [FieldDetailsField.Geolocation]: `${facilityData.geolocation.lat}, ${facilityData.geolocation.lon}`,
    [FieldDetailsField.TimeSlot]: fieldData.time_slot,
    [FieldDetailsField.OpeningHours]: getOpeningHours,
    [FieldDetailsField.TimePrice]: getTimePrice(),
    [FieldDetailsField.TeamSize]: fieldData.max_players,
    [FieldDetailsField.Sports]: getOptions(fieldData.sports),

    [FieldDetailsField.Gender]: isFemaleOnly
      ? FieldCategory.FemaleOnly
      : isMaleOnly
      ? FieldCategory.MaleOnly
      : 'all',
    [FieldDetailsField.FreeAmenities]: getOptions(getFreeAmenities),
    [FieldDetailsField.ChargeableAmenities]: getOptions(getChargeableAmenities),
    [FieldDetailsField.CategoriesType]: getCategoriesType,
    [FieldDetailsField.Type]: fieldData.type,
    [FieldDetailsField.Width]: fieldData.width,
    [FieldDetailsField.Length]: fieldData.length,
    [FieldDetailsField.ExceptionTimePrice]: multiExceptionalValue_,
  };

  const canEdit = canEditField(user);

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  const handleSubmit = (formValues: FieldDetailsFormValues) => {
    formValues.exception_time_price = mulitExcepTimeRange_;
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[], galleryFileList as RcFile[]);
  };

  const getGalleryImagesLength = fieldData?.image_urls?.length || 0;
  // const updateExcepChips = (value: TimePrice) => {
  //   if (!value) return;
  //   const { exception_days: exceptionDaysRange } = value;
  //   const { exception_days_list: exceptionDaysList } = value;

  //   if (exceptionDaysList) {
  //     const exceptionDayList = `${dayjs(exceptionDaysList[0]).format(
  //       'YYYY-MM-DD',
  //     )} - ${dayjs(exceptionDaysList[1]).format('YYYY-MM-DD')}`;
  //     setExcheptionalDate(exceptionDayList);
  //   }

  //   if (exceptionDaysRange) {
  //     const chipsExcepValues = exceptionDaysRange.map(
  //       ({ exception_days_range, exception_days_slot_price }) => ({
  //         label: `${dayjs(exception_days_range[0]).format('hh:mm A')} - ${dayjs(
  //           exception_days_range[1],
  //         ).format('hh:mm A')} | ${exception_days_slot_price} KWD`,
  //       }),
  //     );

  //     setExcepDays(chipsExcepValues);
  //   }
  // };

  return (
    <>
      {/* <div className='packForm'>
        <div className='warp_inner'> */}
      <Form
        {...formItemLayout}
        id='field-details-form'
        name='field-details-form'
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={handleSubmit}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => {
          setIsFormDirty(true);

          // if (value[0].name[0] === 'time_price') {
          //   updateExcepChips(value[0].value);
          // }
        }}
        validateTrigger={validateTrigger}
        disabled={!canEdit}
      >
        {/* <Row> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.company.label')}
          name={FieldDetailsField.Company}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.company.placeholder')}
            size='large'
            disabled
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.facility.label')}
          name={FieldDetailsField.Facility}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.facility.placeholder')}
            size='large'
            disabled
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.area.label')}
          name={FieldDetailsField.Area}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            disabled
            placeholder={t('fields.formFields.area.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.name.label')}
          name={FieldDetailsField.Name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.name.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.nameArabic.label')}
          name={FieldDetailsField.NameArabic}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.nameArabic.placeholder')}
            dir='rtl'
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.rank.label')}
          name={FieldDetailsField.Rank}
          rules={[
            {
              required: true,
            },
            () => ({
              validator(_, value) {
                if (value <= 0) {
                  return Promise.reject(
                    t('facilities.formFields.rank.errorLessThanOne'),
                  );
                } else return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder={t('fields.formFields.rank.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.geolocation.label')}
          name={FieldDetailsField.Geolocation}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            disabled
            placeholder={t('fields.formFields.geolocation.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}

        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.teamSize.label')}
          name={FieldDetailsField.TeamSize}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.teamSize.placeholder')}
            options={getTeamSizeOptions()}
            allowClear
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.sport.label')}
          name={FieldDetailsField.Sports}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SportSelect
            mode='multiple'
            size='large'
            customParams={{ size }}
            // Need to pull all to have label rendered instead of id
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.freeAmenities.label')}
          name={FieldDetailsField.FreeAmenities}
        >
          <AmenitiesSelect
            size='large'
            mode='multiple'
            customParams={{ chargeable: false, is_active: true, size }}
            // Need to pull all to have label rendered instead of id
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.chargeableAmenities.label')}
          name={FieldDetailsField.ChargeableAmenities}
        >
          <AmenitiesSelect
            size='large'
            mode='multiple'
            customParams={{ chargeable: true, is_active: true, size }}
            // Need to pull all to have label rendered instead of id
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.gender.label')}
          name={FieldDetailsField.Gender}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.gender.placeholder')}
            options={genderOptions}
            allowClear
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}>
                <div className='fld_w_wrap'>
                  <div className='fld_w_bx'> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.width.label')}
          name={FieldDetailsField.Width}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            placeholder={t('fields.formFields.width.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </div> */}
        {/* <div className='fld_w_bx'> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.length.label')}
          name={FieldDetailsField.Length}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            placeholder={t('fields.formFields.length.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </div>
                </div>
              </Col> */}

        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.categoriesType.label')}
          name={FieldDetailsField.CategoriesType}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            {typeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.sportType.label')}
          name={FieldDetailsField.Type}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            {sportTypeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.timeSlot.label')}
          name={FieldDetailsField.TimeSlot}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.timeSlot.placeholder')}
            options={timeSlotsOptions}
            allowClear
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}>
                <Form.Item<FieldDetailsField>
                  label={t('fields.formFields.openingHours.label')}
                  name={FieldDetailsField.OpeningHours}
                  style={{ display: 'none' }}
                  rules={[
                    {
                      required: false,
                      validator: (_, value) => {
                        if (value === undefined || value === null) {
                          return Promise.reject(t('validateMessages.required'));
                        }

                        if (value[0].minute() === 59) {
                          return Promise.reject(
                            t('fields.formFields.openingHours.errors.openingHour59'),
                          );
                        }

                        if (value[1].minute() === 59 && value[1].hour() !== 23) {
                          return Promise.reject(
                            t('fields.formFields.openingHours.errors.closingHour59'),
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                  wrapperCol={{ lg: 10, xs: 24 }}
                >
                  <TimePicker.RangePicker
                    size='large'
                    style={{ width: '100%' }}
                    format='h mm A'
                    showSecond={false}
                    inputReadOnly={true}
                    hideDisabledOptions={true}
                    disabledTime={getDisabledMinutes}
                  />
                </Form.Item>
              </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.timePrice.label')}
          name={FieldDetailsField.TimePrice}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePriceInput
            value={getInitialValues[FieldDetailsField.TimePrice]}
            error={timePriceError}
            timeSlot={timeSlot}
            openingHours={openingHours as any}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.excepTimePrice.label')}
          name={FieldDetailsField.ExceptionTimePrice}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ExceptionTimePriceInput
            error={timePriceError}
            timeSlot={timeSlot}
            setMulitExcepTimeRange={setMulitExcepTimeRange_}
            mulitExcepTimeRange={mulitExcepTimeRange_}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item
          label={t('fields.formFields.coverImage.label')}
          getValueFromEvent={(event) => event?.fileList}
        >
          {fieldData.cover_image_url ? (
            <div style={{ display: 'flex' }}>
              <Image
                style={{ borderRadius: '6px' }}
                src={fieldData.cover_image_url}
                placeholder={true}
                preview={false}
              />
              <StyledImageDeleteButton danger onClick={handleDeleteImage}>
                <DeleteOutlined />
              </StyledImageDeleteButton>
            </div>
          ) : (
            <>
              {fileList.length ? (
                <Upload
                  {...uploadProps}
                  name='file'
                  accept='.png, .jpg, .jpeg'
                  maxCount={1}
                  listType='picture-card'
                />
              ) : (
                <ImgCrop rotationSlider aspect={2}>
                  <Dragger {...uploadProps} multiple={false}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>{t('upload.dragOrClick')}</p>
                    <p className='ant-upload-hint'>
                      <Trans
                        i18nKey='upload.hint'
                        components={{ br: <br /> }}
                      />
                    </p>
                  </Dragger>
                </ImgCrop>
              )}
            </>
          )}
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={24}> */}
        <Form.Item
          label={t('fields.formFields.gallery.label')}
          getValueFromEvent={(event) => event?.fileList}
        >
          <Image.PreviewGroup>
            <ImageGroupWrapper>
              {fieldData?.image_urls?.length
                ? fieldData.image_urls.map((galleryImage) => (
                    <ImageWrapper key={galleryImage.url}>
                      <Image
                        style={{
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                        key={galleryImage.url}
                        width={102}
                        height={102}
                        src={galleryImage.url}
                      />
                      <StyledImageDeleteButton
                        danger
                        onClick={() =>
                          handleDeleteGalleryImage(galleryImage.url)
                        }
                        className='hidden'
                      >
                        <DeleteOutlined />
                      </StyledImageDeleteButton>
                    </ImageWrapper>
                  ))
                : null}
              <ImageWrapper key={'uploader'}>
                <ImgCrop rotationSlider aspect={2}>
                  <Upload
                    {...uploadGalleryProps}
                    name='file'
                    accept='.png, .jpg, .jpeg'
                    maxCount={12}
                    listType='picture-card'
                  >
                    {getGalleryImagesLength + galleryFileList.length < 12 &&
                      '+ Upload'}
                  </Upload>
                </ImgCrop>
              </ImageWrapper>
            </ImageGroupWrapper>
          </Image.PreviewGroup>
        </Form.Item>
        {/* </Col> */}
        {/* </Row> */}

        {/* exception dates */}

        {/* {timeSlot &&
        openingHours &&
        excheptionalDate &&
        Object.keys(openingHours)?.length > 0 ? (
          <Form.Item label='Exception Days' name='ExceptionDate'>
            <TimePriceInputWrapper error={false}>
              <TimePriceInputChip>
                {excheptionalDate ? excheptionalDate : ''}
              </TimePriceInputChip>
            </TimePriceInputWrapper>
          </Form.Item>
        ) : null} */}
        {/* end */}
        {/* exception time ranges */}
        {/* {timeSlot &&
        openingHours &&
        // excheptionalDate &&
        Object.keys(openingHours)?.length > 0 ? (
          <Form.Item label='Exception - price' name='ExceptionDay'>
            <TimePriceInputWrapper error={false}>
              {excepDays && excepDays?.length
                ? excepDays.map((exchipsq: chipStateT) => (
                    <TimePriceInputChip key={exchipsq?.label}>
                      {exchipsq?.label}
                    </TimePriceInputChip>
                  ))
                : null}
            </TimePriceInputWrapper>
          </Form.Item>
        ) : null} */}
        {/* End */}

        <Col span={screens.lg ? 24 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button disabled={disabled} type='primary' htmlType='submit'>
              {t('fields.buttons.updateField')}
            </Button>
          </Row>
        </Col>
      </Form>
      {/* </div>
      </div> */}

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
