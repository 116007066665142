import { PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Grid,
  Image as IMG,
  Input,
  message,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { useCreateOfferPopup } from 'features/discount-code/use-cases/create-offer-popup';
import { NotificationApiService } from 'features/notification/api/notification-api.service';
import { NotificationFilterFormValues } from 'features/notification/components/notification-filter-form/type';
import { useState } from 'react';
// import { Image as IMG } from 'antd';

const { useBreakpoint } = Grid;

export const AddOfferPopupModal = ({
  onClose,
  initialValues,
  handleFilter,
  filterForm,
}: {
  onClose: () => void;
  handleFilter: (values: NotificationFilterFormValues) => void;
  initialValues: NotificationFilterFormValues;
  filterForm: any;
}) => {
  const formName = 'add-offer-popup-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();

  const [sportsData, setSportsData] = useState<any>(null); // Track 'Send & Later' action
  const [facilityData, setFacilityData] = useState<any>(null); // Track 'Send & Later' action

  const [popFileList, setPopFileList] = useState<UploadFile[]>([]);
  const [popPreviewImage, setPopPreviewImage] = useState<string>('');
  const [popPreviewOpen, setPopPreviewOpen] = useState<boolean>(false);

  const [redirectVal, setRedirectVal] = useState<any>(null);
  const [packagesData, setPackagesData] = useState<any>(null);

  const { isLoading, mutate } = useCreateOfferPopup();

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };

  const getSportsData = async (redValue: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: redValue === 'Booking' ? 'BookingSports' : 'PackageSports',
      } as any);
      setSportsData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getFacilityData = async (redirectLabel: string, sportsId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: redirectLabel,
        sport_id: sportsId,
      } as any);
      setFacilityData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getPackagesData = async (facilityId: string) => {
    try {
      const apiRes = await NotificationApiService().getNotificationdropDown({
        Flag: 'FacilityWisePackage',
        facility_id: facilityId,
      } as any);
      setPackagesData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const handleUpdate = async (formData: any) => {
    try {
      await form.validateFields();

      if (!popFileList || popFileList.length === 0) {
        form.setFields([
          {
            name: 'ImageFile',
            errors: ['Please upload a popup banner image!'],
          },
        ]);

        return;
      }

      form.setFields([
        {
          name: 'ImageFile',
          errors: [],
        },
      ]);

      const formDataToSend = new FormData();

      formDataToSend.append('Flag', 'Set');
      formDataToSend.append('BannerTypeId', '2');
      formDataToSend.append('Title', formData?.Title);
      formDataToSend.append('Title_Ar', formData?.Title_Ar);
      if (formData?.RedirectionType)
        formDataToSend.append('RedirectionType', formData?.RedirectionType);
      if (formData?.SportId)
        formDataToSend.append('SportId', formData?.SportId);
      if (formData?.FacilityId)
        formDataToSend.append('FacilityId', formData?.FacilityId);
      if (formData?.ItemId) formDataToSend.append('ItemId', formData?.ItemId);

      formDataToSend.append(
        'IsSlot',
        formData?.IsSlot === '1' ? 'true' : 'false',
      );

      if (popFileList && popFileList.length > 0) {
        const filePop: any = popFileList[0].originFileObj;
        formDataToSend.append('ImageFile', filePop || null);
      }

      try {
        // Trigger your mutation with FormData
        mutate(formDataToSend, {
          onSuccess: () => {
            filterForm.resetFields();
            handleFilter({ ...initialValues });
            handleClose(); // Close modal or handle success state
          },
          onError: (error: any) => {
            console.error('Error creating notification:', error); // Handle error
          },
        });
      } catch (error) {
        console.error('Error during mutate:', error);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // popup file change
  const handlePopupPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob); // Generate preview for local files
    }
    setPopPreviewImage(file.url || (file.preview as any)); // Use URL if available; otherwise, use preview
    setPopPreviewOpen(true);
  };

  const handlePopupChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => setPopFileList(newFileList);

  const uploadButtonPop = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleRedirectionChange = (value: string) => {
    form.setFieldValue('RedirectionType', value);
    setRedirectVal(value);
    getSportsData(value);
    form.resetFields(['SportId', 'FacilityId', 'ItemId']);
  };

  const handleSportsChange = (value: string) => {
    form.setFieldValue('SportId', value);
    form.resetFields(['FacilityId']);
    const reLabel =
      redirectVal === 'Booking' ? 'BookingFacility' : 'PackageFacility';
    getFacilityData(reLabel, value);
  };

  const handleFacilityChange = (value: string) => {
    form.setFieldValue('FacilityId', value);
    form.resetFields(['ItemId']);
    getPackagesData(value);
  };

  const checkPopImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          if (width >= 400 && height >= 700) {
            resolve(true);
          } else {
            message.error(
              `Image dimensions must be minimum 400px (width) x 700px (height). Your image is ${width}px x ${height}px.`,
            );
            resolve(false);
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Create Popup Banner'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText={'Save'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
    >
      <Form
        className='form_padding'
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Form.Item
            label='Title'
            name='Title'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter English Title',
              },
            ]}
          >
            <Input placeholder='Enter English Title' maxLength={54} />
          </Form.Item>

          <Form.Item
            label='Title Ar'
            name='Title_Ar'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter Arabic Title',
              },
            ]}
          >
            <Input
              placeholder='Enter Arabic Title'
              maxLength={54}
              dir={'rtl'}
            />
          </Form.Item>

          {/* Redirection Field */}
          <Form.Item
            label='Redirection'
            name='RedirectionType'
            rules={[{ required: true, message: 'Please select Redirection' }]}
          >
            <Select
              allowClear
              placeholder='Please select Redirection'
              options={[
                { value: 'Booking', label: 'Booking' },
                { value: 'Match', label: 'Match' },
                { value: 'Package', label: 'Package' },
              ]}
              onChange={handleRedirectionChange}
            />
          </Form.Item>

          {/* sports id */}

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const redirectionValue = getFieldValue('RedirectionType');
              if (
                redirectionValue === 'Package' ||
                redirectionValue === 'Booking'
              ) {
                return (
                  <Form.Item
                    label='Sports'
                    name='SportId'
                    rules={[
                      { required: true, message: 'Please select Sports' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Sports'
                      options={sportsData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleSportsChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const sportsValue = getFieldValue('SportId');
              if (sportsValue) {
                return (
                  <Form.Item
                    label='Facility'
                    name='FacilityId'
                    rules={[
                      { required: true, message: 'Please select Facility' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Facility'
                      options={facilityData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleFacilityChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Package' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Package'
                    name='ItemId'
                    rules={[
                      { required: true, message: 'Please select Package' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Package'
                      options={packagesData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* is slot - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Booking' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Page'
                    name='IsSlot'
                    // rules={[{ required: true, message: 'Please select Package' }]}
                  >
                    <Select
                      allowClear
                      defaultValue={'0'}
                      placeholder='Please select Page'
                      options={[
                        { value: '0', label: 'Detail' },
                        { value: '1', label: 'Slot' },
                      ]}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Image'
                name='ImageFile'
                help={'Recommend minimum size width: 400px , Height: 700px'}
              >
                <Upload
                  action={undefined}
                  beforeUpload={async (file) => {
                    const isImage = [
                      'image/jpeg',
                      'image/png',
                      'image/jpg',
                    ].includes(file.type);
                    const isSize = file.size < 200000;

                    if (!isImage) {
                      message.error(
                        'You can only upload JPG, JPEG, or PNG files!',
                      );
                    }

                    if (!isSize) {
                      message.error(
                        'You can only upload maximum 200 kb image size',
                      );
                    }

                    const isDimension = await checkPopImageDimensions(file); // Await the result

                    return isImage && isSize && isDimension
                      ? false
                      : Upload.LIST_IGNORE;
                  }}
                  listType='picture-card'
                  fileList={popFileList}
                  onPreview={handlePopupPreview}
                  onChange={handlePopupChange}
                  multiple
                  maxCount={1}
                  accept='.jpg,.jpeg,.png'
                >
                  {popFileList.length >= 1 ? null : uploadButtonPop}
                </Upload>
                {popPreviewImage && (
                  <IMG
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: popPreviewOpen,
                      onVisibleChange: setPopPreviewOpen,
                    }}
                    src={popPreviewImage}
                    alt='Preview Image'
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </figure>
      </Form>
    </Modal>
  );
};
