import { Grid, Spin } from 'antd';
import { Loader, Modal } from 'components';
import { Result404 } from 'components/errors';
import {
  BookingDetailsComments,
  BookingDetailsConfirmationForm,
  BookingDetailsFooter,
  BookingDetailsForm,
  BookingDetailsInfo,
  BookingDetailsTransactionDetails,
  ClientInfo,
  FilterFormValues,
  getBookingDetailsInfoProps,
  useCancelBooking,
  useGetBooking,
  useGetBookingCommentsInfiniteQuery,
  useUpdateBooking,
} from 'features/bookings';
import {
  StyledDetailsContainer,
  StyledDetailsWrapper,
  StyledSeparator,
} from 'features/bookings/components/booking-details/booking-details.styles';
import { useAddBookingComment } from 'features/bookings/use-cases/add-comment';
import { getUserFullName, useGetUser, useUserContext } from 'features/users';
// getUserFullName,
import {
  canEditBooking,
  canViewBookingAuthor,
} from 'features/users/permissions';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

type BookingDetailsModalProps = {
  bookingId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const BookingDetailsModal = ({
  bookingId,
  onCancel,
  onSuccess,
}: BookingDetailsModalProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { user } = useUserContext();
  const { mutate: mutateUpdateBooking, isLoading: isLoadingUpdateBooking } =
    useUpdateBooking();

  const { mutate: mutateCancelBooking, isLoading: isLoadingCancelBooking } =
    useCancelBooking();

  const {
    mutate: mutateAddBookingComment,
    isLoading: isLoadingAddBookingComment,
  } = useAddBookingComment();

  const bookingQueryResult = useGetBooking(bookingId, {
    enabled: true,
    retry: false,
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const bookingCommentsQueryResult = useGetBookingCommentsInfiniteQuery(
    { id: bookingId },
    {
      keepPreviousData: true,
      enabled: true,
      retry: false,
      cacheTime: 0, //workaround to invalidate initialValues of client data form
    },
  );

  const {
    isLoading: isLoadingBooking,
    isError: isErrorBooking,
    isFetching: isFetchingBooking,
    data: bookingData,
  } = bookingQueryResult;

  const {
    isLoading: isLoadingBookingComments,
    isError: isErrorBookingComments,
    isFetching: isFetchingBookingComments,
    data: bookingCommentsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = bookingCommentsQueryResult;

  const canFetchAuthor =
    user &&
    canViewBookingAuthor(user) &&
    Boolean(bookingQueryResult.data?.creator_id);

  const canFetchCanceller =
    user &&
    canViewBookingAuthor(user) &&
    Boolean(bookingQueryResult.data?.cancelled_by_id);

  const creatorQueryResult = useGetUser(
    bookingQueryResult.data?.creator_id as string,
    {
      enabled: canFetchAuthor,
      retry: false,
    },
  );

  const creatorData = creatorQueryResult.data;

  const cancellerQueryResult = useGetUser(
    bookingQueryResult.data?.cancelled_by_id as string,
    {
      enabled: canFetchCanceller,
      retry: false,
    },
  );

  const cancellerData = cancellerQueryResult.data;

  const isLoading = {
    loading:
      isLoadingUpdateBooking ||
      isLoadingCancelBooking ||
      isFetchingBooking ||
      isFetchingBookingComments ||
      isLoadingAddBookingComment,
    isLoadingUpdateBooking,
    isLoadingCancelBooking,
  };

  const handleBookingCancellation = () => {
    if (!bookingData) return;

    mutateCancelBooking(bookingData.id, { onSuccess });
  };

  const canEdit = () => {
    if (!user) return;
    if (!bookingData) return;

    return (
      canEditBooking(user, bookingData).discount ||
      canEditBooking(user, bookingData).firstName ||
      canEditBooking(user, bookingData).lastName ||
      canEditBooking(user, bookingData).payment ||
      canEditBooking(user, bookingData).phoneNumber ||
      canEditBooking(user, bookingData).presence
    );
  };

  const handleUpdate = ({
    phone_number,
    first_name,
    last_name,
    discount,
    comment,
  }: FilterFormValues) => {
    if (!user) return;
    if (!bookingData) return;

    const now = new Date();
    const endDate = new Date(bookingData?.end_date);
    const isCancelledOrIsCompleted = bookingData.is_cancelled || endDate < now;

    if (!isCancelledOrIsCompleted && canEdit()) {
      const payload = {
        id: bookingData.id,
        phone_number: canEditBooking(user, bookingData).phoneNumber
          ? phone_number
          : undefined,
        first_name: canEditBooking(user, bookingData).firstName
          ? first_name
          : undefined,
        last_name: canEditBooking(user, bookingData).lastName
          ? last_name
          : undefined,
        // discount: canEditBooking(user, bookingData).discount
        //   ? discount
        //   : undefined,
        discount: discount,
      };
      const commentPayload = {
        id: bookingData.id,
        text: comment,
      };

      mutateUpdateBooking(payload, {
        onSuccess: () => {
          if (comment?.length) mutateAddBookingComment(commentPayload);

          onSuccess();
        },
      });
    } else {
      const payload = {
        id: bookingData.id,
        text: comment,
      };

      if (comment?.length) mutateAddBookingComment(payload, { onSuccess });
    }
  };

  const getPages = () => {
    const pages = bookingCommentsData?.pages;

    return Array.isArray(pages) ? pages : [];
  };
  const mapComments = () =>
    getPages()
      .map((page) => page.items)
      .flat();

  return (
    <Modal
      className='bk_det_modal'
      open
      title={t('bookings.heading.bookingInfo')}
      onCancel={onCancel}
      getContainer={false}
      width={screens.lg ? '920px' : '100%'}
      wrapClassName='booking-details-modal'
      footer={[
        <BookingDetailsFooter
          bookingData={bookingData}
          creatorData={creatorData}
          cancellerData={cancellerData}
          key='booking-details-footer'
          handleBookingCancellation={handleBookingCancellation}
          isLoading={isLoading}
        />,
      ]}
      // bodyStyle={{ maxHeight: screens.lg ? '70vh' : undefined }}
    >
      {isLoadingBooking || isLoadingBookingComments ? (
        <Loader />
      ) : isErrorBooking || isErrorBookingComments ? (
        <Result404 />
      ) : (
        <Spin spinning={isLoading.loading}>
          <StyledDetailsWrapper>
            <StyledDetailsContainer>
              {bookingData.transaction && (
                <ClientInfo>{getUserFullName(bookingData.user)}</ClientInfo>
              )}
              <BookingDetailsInfo
                {...getBookingDetailsInfoProps(bookingData)}
              />
              {bookingData?.transaction && (
                <BookingDetailsConfirmationForm
                  bookingData={bookingData}
                  onSuccess={onSuccess}
                  mutateUpdateBooking={mutateUpdateBooking}
                  isLoading={isLoading.loading}
                />
              )}
              <BookingDetailsTransactionDetails
                transaction={bookingData.transaction}
                bookingId={bookingData.order_no}
              />
            </StyledDetailsContainer>

            {!screens.lg && <StyledSeparator />}

            {bookingData?.transaction && (
              <StyledDetailsContainer
                className='no-gap'
                onScroll={(e) => {
                  if (!e.target) return;

                  const target = e.target as HTMLDivElement;
                  const isScrolledToBottom =
                    target.scrollTop + target.offsetHeight >=
                    target.scrollHeight;

                  if (!isScrolledToBottom) return;

                  if (hasNextPage || !isFetchingNextPage) {
                    fetchNextPage();
                  }
                }}
              >
                <BookingDetailsForm
                  bookingData={bookingData}
                  handleUpdate={handleUpdate}
                />
                {mapComments().length ? (
                  <BookingDetailsComments
                    bookingCommentsData={mapComments()}
                    loadMoreComments={fetchNextPage}
                    hasNextPage={hasNextPage}
                  />
                ) : null}
              </StyledDetailsContainer>
            )}
          </StyledDetailsWrapper>
        </Spin>
      )}
    </Modal>
  );
};
