import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum OfferFilterFormField {
  title = 'Title',
  Date = 'date',
  status = 'IsActive',
}
export type OfferFilterFormValues = {
  [OfferFilterFormField.title]: string | undefined;
  [OfferFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [OfferFilterFormField.status]: string | undefined;
};

export type OfferFilterFormPaginated = OfferFilterFormValues &
  PaginatedRequestParams;
