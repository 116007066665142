import { PlusOutlined } from '@ant-design/icons';
import {
  // Checkbox,
  Col,
  Form,
  Grid,
  Image as IMG,
  Input,
  message,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Modal } from 'components';
import { DiscountApiService } from 'features/discount-code/api/discount-api.service';
import { OfferFilterFormValues } from 'features/discount-code/components/filter-form/offer-filter-form/type';
import { useCreateOffer } from 'features/discount-code/use-cases/create-offer';
import { useEffect, useState } from 'react';
// import { Image as IMG } from 'antd';

const { useBreakpoint } = Grid;

export const AddOfferModal = ({
  onClose,
  initialValues,
  handleFilter,
  filterForm,
}: {
  onClose: () => void;
  handleFilter: (values: OfferFilterFormValues) => void;
  initialValues: OfferFilterFormValues;
  filterForm: any;
}) => {
  const formName = 'add-offer-banner-form';
  // const { Option } = Select;
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();

  const [sportsData, setSportsData] = useState<any>(null); // Track 'Send & Later' action
  const [facilityData, setFacilityData] = useState<any>(null); // Track 'Send & Later' action
  const [tagData, setTagData] = useState<any>(null); // Track 'Send & Later' action

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const [redirectVal, setRedirectVal] = useState<any>(null);
  const [packagesData, setPackagesData] = useState<any>(null);

  const [couponType, setCouponType] = useState<any>(null);

  const { isLoading, mutate } = useCreateOffer();

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };

  const getSportsData = async (redValue: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: redValue === 'Booking' ? 'BookingSports' : 'PackageSports',
      } as any);
      setSportsData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getFacilityData = async (redirectLabel: string, sportsId: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: redirectLabel,
        sport_id: sportsId,
      } as any);
      setFacilityData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getPackagesData = async (facilityId: string) => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: 'FacilityWisePackage',
        facility_id: facilityId,
      } as any);
      setPackagesData(apiRes);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const getTagData = async () => {
    try {
      const apiRes = await DiscountApiService().getDiscountDropDown({
        Flag: 'tags',
      } as any);
      setTagData(
        apiRes.items.map((item: any) => ({
          label: item.name,
          value: item.int_id,
        })),
      );
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    getTagData();
  }, []);

  const handleUpdate = async (formData: any) => {
    try {
      await form.validateFields();

      if (!fileList || fileList.length === 0) {
        form.setFields([
          {
            name: 'ImageFile',
            errors: ['Please upload a banner image!'],
          },
        ]);

        return;
      }

      form.setFields([
        {
          name: 'ImageFile',
          errors: [],
        },
      ]);

      // const sendTags =
      //   formData.TagList
      //     ? formData.TagList.map((tag: any) => ({
      //       TagId: tag,
      //     }))
      //     : null;
      const sendTags = [{ TagId: formData.TagList }];

      const formDataToSend = new FormData();

      formDataToSend.append('Flag', 'Set');
      formDataToSend.append('Title', formData?.Title);
      formDataToSend.append('Title_Ar', formData?.Title_Ar);
      formDataToSend.append('Description', formData?.Description);
      formDataToSend.append('Description_Ar', formData?.Description_Ar);
      formDataToSend.append('DiscountTypeId', formData?.DiscountTypeId);
      formDataToSend.append(
        'DiscountType',
        formData?.DiscountTypeId === 1 ? 'Flat' : 'Percent',
      );
      formDataToSend.append('DiscountValue', formData?.DiscountValue);
      if (formData?.RedirectionType)
        formDataToSend.append('RedirectionType', formData?.RedirectionType);
      if (formData?.SportId)
        formDataToSend.append('SportId', formData?.SportId);
      if (formData?.FacilityId)
        formDataToSend.append('FacilityId', formData?.FacilityId);
      if (formData?.ItemId) formDataToSend.append('ItemId', formData?.ItemId);

      formDataToSend.append(
        'IsSlot',
        formData?.IsSlot === '1' ? 'true' : 'false',
      );

      if (fileList && fileList.length > 0) {
        const file: any = fileList[0].originFileObj;
        formDataToSend.append('ImageFile', file || null);
      }

      if (sendTags?.length > 0)
        formDataToSend.append('TagList', JSON.stringify(sendTags));

      try {
        // Trigger your mutation with FormData
        mutate(formDataToSend, {
          onSuccess: () => {
            filterForm.resetFields();
            handleFilter({ ...initialValues });
            handleClose(); // Close modal or handle success state
          },
          onError: (error: any) => {
            console.error('Error creating notification:', error); // Handle error
          },
        });
      } catch (error) {
        console.error('Error during mutate:', error);
      }
    } catch (error) {
      console.error('Error during mutate:', error);
    }
  };

  const handleKeyValueDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      '.',
      'Enter',
    ];
    const isNumber = /^[0-9]$/;

    if (!allowedKeys.includes(e.key) && !isNumber.test(e.key)) {
      e.preventDefault();
    }

    // Prevent multiple decimals
    if (
      e.key === '.' &&
      (e.currentTarget.value.includes('.') || e.currentTarget.value === '')
    ) {
      e.preventDefault();
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob); // Generate preview for local files
    }
    setPreviewImage(file.url || (file.preview as any)); // Use URL if available; otherwise, use preview
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleRedirectionChange = (value: string) => {
    form.setFieldValue('RedirectionType', value);
    setRedirectVal(value);
    getSportsData(value);
    form.resetFields(['SportId', 'FacilityId', 'ItemId']);
  };

  const handleSportsChange = (value: string) => {
    form.setFieldValue('SportId', value);
    form.resetFields(['FacilityId']);
    const reLabel =
      redirectVal === 'Booking' ? 'BookingFacility' : 'PackageFacility';
    getFacilityData(reLabel, value);
  };

  const handleFacilityChange = (value: string) => {
    form.setFieldValue('FacilityId', value);
    form.resetFields(['ItemId']);
    getPackagesData(value);
  };

  const checkImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          if (width >= 200 && height >= 200) {
            resolve(true);
          } else {
            message.error(
              `Image dimensions must be minimum 200px (width) x 200px (height). Your image is ${width}px x ${height}px.`,
            );
            resolve(false);
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCouponTypeChange = (value: string) => {
    form.setFieldValue('DiscountTypeId', value);
    form.resetFields(['DiscountValue']);
    setCouponType(value);
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Create Offer'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText={'Save'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
    >
      <Form
        className='form_padding'
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Form.Item
            label='Title'
            name='Title'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter English Title',
              },
            ]}
          >
            <Input placeholder='Enter English Title' maxLength={54} />
          </Form.Item>

          <Form.Item
            label='Title Ar'
            name='Title_Ar'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter Arabic Title',
              },
            ]}
          >
            <Input
              placeholder='Enter Arabic Title'
              maxLength={54}
              dir={'rtl'}
            />
          </Form.Item>

          <Form.Item
            label='Description'
            name='Description'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter English Description',
              },
            ]}
          >
            <TextArea placeholder='Enter English Description' rows={5} />
          </Form.Item>

          <Form.Item
            label='Description Ar'
            name='Description_Ar'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter Arabic Description',
              },
            ]}
          >
            <TextArea
              placeholder='Enter Arabic Description'
              dir={'rtl'}
              rows={5}
            />
          </Form.Item>

          <Form.Item
            label='Discount Type'
            name='DiscountTypeId'
            rules={[
              { required: true, message: 'Please select Discount Type ' },
            ]}
          >
            <Select
              placeholder='Please select Discount Type'
              options={[
                { value: 1, label: 'Flat' },
                { value: 2, label: 'Percent' },
              ]}
              onChange={handleCouponTypeChange}
            />
          </Form.Item>

          <Form.Item
            label='Value'
            name='DiscountValue'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter value',
              },
            ]}
          >
            <Input
              addonAfter={couponType === 2 ? '%' : null}
              placeholder='Enter Value'
              onKeyDown={handleKeyValueDown}
              maxLength={5} // Optional: Prevent long inputs
              onChange={(e) => {
                const value = e.target.value;
                const discountTypeId = form.getFieldValue('DiscountTypeId');
                if (discountTypeId === 2 && parseFloat(value) >= 100) {
                  form.setFieldsValue({ DiscountValue: '100' }); // Cap the value to 99.9
                }
              }}
            />
          </Form.Item>

          {/* Redirection Field */}
          <Form.Item
            label='Redirection'
            name='RedirectionType'
            // rules={[{ required: true, message: 'Please select Redirection' }]}
          >
            <Select
              allowClear
              placeholder='Please select Redirection'
              options={[
                { value: 'Booking', label: 'Booking' },
                { value: 'Match', label: 'Match' },
                { value: 'Package', label: 'Package' },
              ]}
              onChange={handleRedirectionChange}
            />
          </Form.Item>

          {/* sports id */}

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const redirectionValue = getFieldValue('RedirectionType');
              if (
                redirectionValue === 'Package' ||
                redirectionValue === 'Booking'
              ) {
                return (
                  <Form.Item
                    label='Sports'
                    name='SportId'
                    rules={[
                      { required: true, message: 'Please select Sports' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Sports'
                      options={sportsData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleSportsChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const sportsValue = getFieldValue('SportId');
              if (sportsValue) {
                return (
                  <Form.Item
                    label='Facility'
                    name='FacilityId'
                    rules={[
                      { required: true, message: 'Please select Facility' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Facility'
                      options={facilityData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={handleFacilityChange}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* Facility Field - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Package' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Package'
                    name='ItemId'
                    rules={[
                      { required: true, message: 'Please select Package' },
                    ]}
                  >
                    <Select
                      showSearch // Enable search functionality
                      placeholder='Please select Package'
                      options={packagesData?.items.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        typeof option?.label === 'string' &&
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>

          {/* is slot - Conditionally Rendered */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const facilityIdVal = getFieldValue('FacilityId');
              if (redirectVal === 'Booking' && facilityIdVal) {
                return (
                  <Form.Item
                    label='Page'
                    name='IsSlot'
                    // rules={[{ required: true, message: 'Please select Package' }]}
                  >
                    <Select
                      allowClear
                      defaultValue={'0'}
                      placeholder='Please select Page'
                      options={[
                        { value: '0', label: 'Detail' },
                        { value: '1', label: 'Slot' },
                      ]}
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                label='Image'
                name='ImageFile'
                help={'Recommend minimum size width: 200px, Height: 200px'}
              >
                <Upload
                  action={undefined}
                  beforeUpload={async (file) => {
                    const isImage = [
                      'image/jpeg',
                      'image/png',
                      'image/jpg',
                    ].includes(file.type);
                    const isSize = file.size < 200000; //

                    if (!isImage) {
                      message.error(
                        'You can only upload JPG, JPEG, or PNG files!',
                      );
                    }

                    if (!isSize) {
                      message.error(
                        'You can only upload maximum 200 kb image size',
                      );
                    }

                    const isDimension = await checkImageDimensions(file); // Await the result

                    return isImage && isSize && isDimension
                      ? false
                      : Upload.LIST_IGNORE;
                  }}
                  listType='picture-card'
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  multiple
                  maxCount={1}
                  accept='.jpg,.jpeg,.png'
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <IMG
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: setPreviewOpen,
                    }}
                    src={previewImage}
                    alt='Preview Image'
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            name='TagList'
            label='Select Tags'
            rules={[
              { required: true, message: 'Please select at least one tag!' },
            ]}
          >
            <Select
              mode='multiple'
              placeholder='Please select Tags'
              optionLabelProp='label'
              style={{ width: '100%' }}
              // dropdownRender={(menu) => (
              //   <>
              //     {menu}
              //     <div style={{ display: "flex", padding: "8px" }}>
              //       <Checkbox.Group
              //         options={tagData}
              //         onChange={(checkedValues) => {
              //           form.setFieldsValue({ tags: checkedValues });
              //         }}
              //         value={form.getFieldValue("tags") || []}
              //       />
              //     </div>
              //   </>
              // )}
            >
              {tagData?.length > 0 &&
                tagData.map((option: any) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  >
                    <Checkbox
                      checked={
                        (form.getFieldValue('tags') || []).indexOf(
                          option.value,
                        ) > -1
                      }
                    >
                      {option.label}
                    </Checkbox>
                  </Option>
                ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name='TagList'
            label='Select Tags'
            rules={[{ required: true, message: 'Please select tag!' }]}
          >
            <Select
              showSearch // Enable search functionality
              placeholder='Please select tag'
              options={tagData}
              filterOption={(input, option) =>
                typeof option?.label === 'string' &&
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </figure>
      </Form>
    </Modal>
  );
};
