import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { NotificationApiService } from 'features/notification/api/notification-api.service';
import { UserListParams, UserResponse } from 'features/users';
// import { NotificationQueryKeys } from 'features/users/use-cases/query-keys';
import qs from 'qs';

import { NotificationQueryKeys } from './query.keys';
const { getNotification } = NotificationApiService();

const getUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UserResponse>;
  options?: UseQueryOptions<UserResponse>;
} => ({
  queryKey: NotificationQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getNotification(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetNotificationList = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<UserResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
