import { Button, Grid, message, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { PackageApiService } from 'features/package/api/package-api.service';
import { PackageFilterFormPaginated } from 'features/package/components/package-filter-form/type';
import { usePermissions, useUserContext } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Package } from 'types/package';
import { UserWithithdraw } from 'types/withdraw';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
const { useBreakpoint } = Grid;

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: PackageFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<PackageFilterFormPaginated>>;
  refetch: any;
};

export const PackageViewBookingTable = ({
  data,
  isLoading,
  tableParams,
  refetch,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const permissions = usePermissions();
  if (!permissions) return null;
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  // const tableWidth = screens.xxl ? { x: 1440 } : { x: 1100 };
  const handleBooked = async (record: any) => {
    const data1 = {
      booking_id: record,
    };
    try {
      const apiRes: any = await PackageApiService().updateBooking(data1);
      if (apiRes.response_status == 0) {
        message.warning('Can Not Cancel Past Bookings');
      } else {
        message.success('Cancel successfully');
      }
      refetch();
    } catch (error) {
      message.error('err');
    }
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('package.packagefound')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <div className='table_pckge'>
        <Table<Package>
          loading={isLoading}
          dataSource={data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
          onRow={(record: any) => {
            return {
              onClick: () => {
                const selection = window?.getSelection()?.toString();
                if (!selection?.length) {
                  navigate(
                    getRoute(ROUTES.PACKAGE_BOOKING_DETAILS, record.booking_id),
                  );
                }
              },
            };
          }}
        >
          <Column title={'Customer'} dataIndex={'customer_name'} />
          <Column<UserWithithdraw>
            title={'Phone'}
            dataIndex={'customer_phone_number'}
          />
          <Column title={'Company'} dataIndex={'company_name'} />
          <Column<UserWithithdraw> title={'Type'} dataIndex={'package_type'} />
          <Column<UserWithithdraw>
            title={'Facility'}
            dataIndex={'facility_name'}
            width={screens.xxl ? 'auto' : 130}
          />
          <Column<UserWithithdraw>
            title={'Package'}
            dataIndex={'package_name'}
          />
          <Column<UserWithithdraw>
            title={'Amount'}
            dataIndex={'paid_amount'}
            render={(_, record: any) => <>{record.paid_amount} KD</>}
          />
          <Column<UserWithithdraw>
            title={'Booking Date'}
            dataIndex={'last_transaction_date'}
            width={screens.xxl ? 'auto' : 170}
            render={(_, record: any) => (
              <>
                {formatDate({
                  date: record.transaction_date,
                  format: 'date',
                  timeZone: DEFAULT_TIMEZONE,
                } as any)}
              </>
            )}
          />
          <Column<UserWithithdraw>
            title={'Booking'}
            dataIndex={'booking_status_name'}
          />
          {user?.role !== 'ClientAdmin' &&
            user?.role !== 'Li3ibAdmin' &&
            user?.role !== 'Accountant' &&
            user?.role !== 'CallCenterOperative' &&
            user?.role !== 'Coordinator' && (
              <Column<UserWithithdraw>
                title={t('package.table.action')}
                dataIndex={'last_transaction_date'}
                render={(_, record: any) => (
                  <Space>
                    {record.booking_status_id === 1 ? (
                      <Button
                        type='primary'
                        onClick={(event) => {
                          event.stopPropagation(); // Stop event propagation
                          handleBooked(record.booking_id);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      'No action'
                    )}
                  </Space>
                )}
              />
            )}
        </Table>
      </div>
    </StyledTableWrapper>
  );
};
