import { Form, FormInstance, Input } from 'antd';
import trofy from 'features/social-match/components/image/Trofy.png';
import { QuestionrFormFields } from 'features/user-level/components/interface/type';
import { useEffect } from 'react';
export const SocialInfoEditDetails = ({
  form,
  SocailData,
}: {
  form: FormInstance<QuestionrFormFields>;
  SocailData: any;
}) => {
  useEffect(() => {
    form.getFieldValue('hello');
  });

  return (
    <>
      <div className='sm_pndl'>
        {/* <div className='smpnm'>
          <span className='media'>
            <img src={SocailData.sport_icon_link} />
          </span>
          <span className='smttl'>{SocailData.sport_name}</span>
        </div> */}
        {/* <div className='sm_mttl'>
          <div className='media'>
            <img src='https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png' />
          </div>
          <div className='smpnttl'>
            <h3>{SocailData.company_name}</h3>
            <p>
              {SocailData.facility_name} - {SocailData.field_name}
            </p>
          </div>
        </div> */}
        {/* <div className='sm_dtl'>
          <p>{moment(SocailData.start_date).format('LLLL')}</p>
          <p>
            <span
              className={`${
                SocailData.match_status === 'Created' ? 'ttlp_wr' : 'ttlp_lv'
              }`}
            >
              {SocailData.match_status}
            </span>
          </p>
        </div> */}
      </div>
      {/* SocailData.match_status_id == 100 */}
      {SocailData.match_status_id == 2 ||
      SocailData.match_status_id == 5 ||
      SocailData.match_status_id == 3 ? (
        <div className='plsection'>
          <div className='plr_list_edit'>
            {/* Render players for team A */}
            <div className='cell'>
              {SocailData?.player_list
                .filter((player: any) => player.team === 'A')
                .map((res: any, index: number) => (
                  <div className='cellsub' key={index}>
                    <div className='media'>
                      <img
                        src={
                          res.image_url ||
                          'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                        }
                        alt=''
                      />
                    </div>
                    <h3>{res.player_name}</h3>
                    {SocailData.winner_team == 'A' && (
                      <img className='trfyimg' src={trofy} />
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className='plr_list_edit'>
            {/* Render players for team B */}
            <div className='cell'>
              {SocailData?.player_list
                .filter((player: any) => player.team === 'B')
                .map((res: any, index: number) => (
                  <div className='cellsub' key={index}>
                    <div className='media'>
                      <img
                        src={
                          res.image_url ||
                          'https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png'
                        }
                        alt=''
                      />
                    </div>
                    <h3>{res.player_name}</h3>
                    {SocailData.winner_team == 'B' && (
                      <img className='trfyimg' src={trofy} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {SocailData.match_status_id == 2 ||
      SocailData.match_status_id == 5 ||
      SocailData.match_status_id == 3 ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <div className='inti_user01'>
              <Form.Item
                name='team_a_score'
                // name={SocailFormFields.team_a_score}
                colon={false}
                style={{
                  width: '25%',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
              <Form.Item
                name='team_a_score1'
                colon={false}
                style={{
                  width: '25%',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
              <Form.Item
                name='team_a_score2'
                colon={false}
                style={{ width: '25%', marginBottom: '10px' }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
            </div>
            <div className='inti_user02'>
              <Form.Item
                name='team_b_score'
                // name={SocailFormFields.team_b_score}
                style={{
                  width: '25%',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
              <Form.Item
                name='team_b_score2'
                // name={SocailFormFields.team_b_score2}
                style={{
                  width: '25%',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
              <Form.Item
                name='team_b_score3'
                // name={SocailFormFields.team_b_score3}
                style={{ width: '25%', marginBottom: '10px' }}
              >
                <Input
                  disabled={SocailData.match_status == 'Match Complete'}
                  onKeyPress={(e) => {
                    // Check if the entered key is a number or Backspace (to allow deletion)
                    const isNumber = /^[0-9]$/.test(e.key);
                    const isBackspace = e.key === 'Backspace';

                    // Get the current input value
                    const inputValue =
                      (e.target as HTMLInputElement).value || '';

                    // Allow only single digit numbers and Backspace
                    if (!isNumber && !isBackspace) {
                      e.preventDefault();

                      return;
                    }

                    // Prevent input if there are already 2 digits
                    if (inputValue.length >= 1 && !isBackspace) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete='off'
                />
              </Form.Item>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
