import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { DiscountApiService } from 'features/discount-code/api/discount-api.service';
import { DiscountQueryKeys } from 'features/discount-code/use-cases/query.keys';

const { createOffer } = DiscountApiService();

export const useUpdateOffer = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FormData) => {
      return createOffer(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: DiscountQueryKeys.all,
        });
        if (data?.ResponseStatus == 1) {
          message.success('Offer Updated successfully');
        } else {
          message.error(data?.ResponseMessage);
        }
      },
    },
  );
};
